.input {
    width: 100%;
    height: 35px;
    padding: 3px 20px 3px 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    color: #555;
    border-radius: 3px;

    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.input::placeholder {
    color: #959595;
    font-size: 12px;
}

.input:disabled {
    cursor: not-allowed;
    background: white;
    color: rgba(0, 0, 0, .8);
}

.label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 12px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #959595;
    font-size: 10px;
    line-height: 1;
    left: 6px;
}

.container:focus-within label, .filled {
    transform: translate(0, -5px) scale(0.8);
    font-size: 13px;
    background-color: white;
}

.clearIcon {
    position: absolute;
    right: 6px;
    top: 12px;
    color: var(--red-color);
    opacity: .4;
    font-size: 11px;
    cursor: pointer;
    transition: all 0.5s;
}

.clearIcon:hover {
    opacity: 1;
}
