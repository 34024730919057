.button {
    background-color: #7FB98B;
    color: white;
    border-radius: 2px;
    border: none;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    height: 27px;
    margin-right: 15px;
}

.button svg {
    font-size: 14px;
}