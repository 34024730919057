.container {
    width: 100%;
    height: 55px;
    padding: 0 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container[data-children='true'] > div:first-child {
    flex: 1;
}

.container > div:first-child {
    display: flex;
    align-items: center;
    height: 100%;
}

.container[data-view='Mobile'] {
    display: block !important;
    height: 110px;
}

.container[data-view='Mobile'] > div {
    height: 55px;
}

.container[data-view='Mobile'] > div:first-child {
    display: flex;
    align-items: center;
}

.container[data-view='Mobile'] > div:last-child {
    justify-content: end;
}
