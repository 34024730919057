.container {
    width: 100%;
}

.container[data-view='full'] {
    display: flex;

}

.container:not([data-view='side']):not([data-count='1']) > div {
    border-right: 1px solid #dbdbdb;
}

.container:not([data-view='full']) > div {

}
