.driverLabel {
    width: 100%;
    background-color: #F7F7F7;
    color: #4E4E4E;
    font-weight: bold;
    font-size: 14px;
    border: #DBDBDB solid 1px;
    border-radius: 1px;
    padding: 10px 5px;
    margin: 10px 0;
}

.group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    &.start {
        align-items: flex-start;
    }
}

.group > div {
    width: 50%;
}

@media (max-width: 1024px) {
    .group {
        flex-direction: column;
        gap: 5px;
    }

    .group > div {
        width: 100%;
    }
}

.suggest {
    position: absolute;
    background-color: lightgrey;
    height: 200px;
    overflow-y: scroll;
}

/* CSS стили для поля ввода телефонного номера при отключении */
.disabledPhoneInput {
    /* Применяем серый цвет текста */
    color: grey;
    /* Применяем серый фон */
    background-color: grey;
    /* Устанавливаем курсор в виде стрелки */
    cursor: not-allowed;
}