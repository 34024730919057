.row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.row > div {
    width: 50%;
}

@media screen and (max-width: 876px) {
    .row {
        display: block;
    }

    .row > div {
        width: 100%;
    }
}