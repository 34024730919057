.row {
    font-size: 11px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    height: 25px;
}

.icon {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    color: #e0e0e0;
}

.checkbox {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeleton {
    flex: 1;
    display: flex;
    align-items: center;
}