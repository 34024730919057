.container {
    height: calc(98vh - 35px);
    display: flex;
    flex-direction: column;
}

.container > div:last-child {
    flex: 1;
    border-top: 1px solid var(--main-orange-color);
}

@media screen and (max-width: 1024px) {
    .container > div:first-child {
        display: none;
    }

    .container > div:last-child {
        border-top: none;
    }
}