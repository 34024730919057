.container {

    margin: 5px;
    border-radius: 2px;
    padding: 5px;
    min-height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.eventsOuterContainer {
    padding: 10px;
    width: 100%;
    overflow: auto;
}

.eventsOuterContainer > div {
    display: flex;
}
