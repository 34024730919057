.container {
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
}

.container[data-type='default'] {
    grid-template-rows: 100%;
}

.container[data-type='h'] {
    grid-template-rows: auto 1fr;
}

.filters {
    overflow: hidden;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0 5px;
    gap: 10px;
    border-top: 1px solid #cccccc;
}

.flexG7 {
    display: flex;
    gap: 7px;
}

.flexG5 {
    display: flex;
    gap: 5px;
}

.collapseHeaderSaveIcon {
    font-size: 13px;
    cursor: pointer;
    color: black
}

.disabled {
    cursor: not-allowed;
}

.resetBtn {
    border: none;
    background: inherit;
    color: #292929;
    margin: 0 10px;
    font-size: 11px;
    text-decoration: none;
    cursor: pointer;
    opacity: .6;
}

.resetBtn:hover {
    opacity: 1;
}

.resetBtn:disabled {
    opacity: .6;
    cursor: not-allowed;
}

.filterRowText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filterRow {
    margin: 2px 0;
    font-size: 14px;

    border-radius: 2px;
    cursor: pointer;
    padding: 5px 1px;
    display: grid;
    grid-template-columns:20px calc(100% - 35px) 15px;
}

.filterRowFourColumn {
    grid-template-columns:20px calc(100% - 50px) 15px 15px;
}

.filterRow:hover {
    color: var(--main-orange-color);
}

.filterIcon {
    color: #363636;
    font-size: 15px;
}

.selectedIcon {
    color: #3F8CE5;
}

.selected {
    color: #3F8CE5;
}

.center {

    align-items: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pinned {
    color: #3F8CE5 !important;
}

.pinIcon {
    font-size: 12px;
    color: #2c2c2c;
}

.trashIcon {
    color: #2c2c2c;
    font-size: 14px;
}

.trashIcon:hover {
    color: red !important;
}

.header {
    background-color: #f7f7f7;
    border-bottom: 1px solid #dbdbdb;
}

:global(div[data-display='absolute']) .btnContainer {
    width: 100% !important;
}

.btnContainer {
    z-index: 1001;
    position: absolute;
    bottom: 0;
    width: 360px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-backdrop-filter: blur(1px) saturate(35%);
    backdrop-filter: blur(1px) saturate(35%);
}

.btnContainer > button {
    height: 40px;
    font-size: 12px;
    width: 85%;
    background-color: var(--main-orange-color) !important;
    text-transform: uppercase;
}

.separator {
    height: 1px;
    width: 100%;
    background-color: #dbdbdb;
}
