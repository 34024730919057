.container {
   /* margin: 15px 5px 0 10px;*/
}

.label {
    display: flex;
}

.address {
    font-size: 12px;
    color: inherit;
    font-weight: 700;
    min-height: 14px;
}

.dates {
    margin-top: 2px;
    line-height: 12px;
    min-height: 14px;
}

.passed {
    color: #4a4a4a;
}

.typeIcon {
    margin-right: 5px;
    font-size: 16px;
}

.typeIcon[data-sp-type='[P]'] {
    color: var(--main-orange-color);
}

.typeIcon[data-sp-type='[D]'] {
    color: #7FB98B;
}


.date {
    font-weight: 700;
    color: #969696;
    font-size: 12px;
}

.factDate {
    font-weight: 700;
    font-size: 12px;
}

.pen {
    font-size: 16px;
    color: var(--main-orange-color);
    cursor: pointer;
    margin-left: 5px;
}

.plus {
    font-size: 24px;
    color: var(--main-orange-color);
    cursor: pointer;
}

.ban {
    font-size: 12px;
    color: var(--red-color);
    cursor: pointer;
    margin-left: 5px;
}

.comment {
    font-size: 16px;
    color: var(--main-orange-color);
    cursor: pointer;
    float: right;
}
