.container {
    display: grid;
    grid-template-columns: 130px calc(100% - 130px);
}

.wi {
    grid-template-columns: 40px 130px calc(100% - 170px);
}

.iconContainer {
    height: 30px;
    width: 40px;
    border: 1px solid #D8D8D8;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: none;
    background-color: white;
}

.iconContainer svg {
    color: var(--main-orange-color);
}
