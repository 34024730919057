.button {
    padding: 8px;
    font-size: 12px;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background 0.2s ease-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-family: inherit;
    height: 38px;
    text-transform: uppercase;
    min-width: 150px;
}

.button:not([data-type='transparent']):disabled {
    cursor: not-allowed;
    background-color: gray !important;
}

.button[data-type='transparent']:disabled {
    cursor: not-allowed;
}

.button:not(:disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.button[data-type='warning'] {
    background-color: #e54c27;
}

.button[data-type='primary'] {
    background-color: var(--main-orange-color);
}

.button[data-type='secondary'] {
    background-color: #001d64;
}

.button[data-type='error'] {
    background-color: var(--red-color);
}

.button[data-type='third'] {
    background-color: #7FB98B;
    height: 24px;
    padding: 0;
}

.button[data-type='transparent'] {
    color: #333;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    padding: 7px;
}

.loadingSpan {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 10px;
}

.loadingSpan svg {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    font-size: 15px;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
