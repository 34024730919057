.form-layout-container {
    display: flex;
}

.side .form-layout-container {
    flex-direction: column;
}

.form-layout-container[data-count='3'][data-separator='true'] > div.form-layout-column:first-child {
    width: calc(50% - 1px);
}
.form-layout-container[data-count='3'][data-separator='true'] > div.form-layout-column:last-child {
    width: 25%;
}
.form-layout-container[data-count='2'][data-separator='true'] > div.form-layout-column:first-child {
    width: calc(50% - 1px);
}

.form-layout-container[data-count='3'][data-separator='true'] > div.form-layout-column {
    width: calc(25% - 1px);
}

.form-layout-container[data-count='2'] > div.form-layout-column {
    width: 50%;
}

.side .form-layout-container[data-count='3'] > div.form-layout-column {
    width: 100% !important;
}
.side .form-layout-container[data-count='2'] > div.form-layout-column {
    width: 100% !important;
}
@media (max-width: 1024px) {
    .form-layout-container {
        flex-direction: column;
    }

    .form-layout-container > div.form-layout-column {
        width: 100% !important;
    }

    .form-layout-column-separator {
        display: none;
    }
}

.form-layout-column-separator {
    width: 1px;
    background-color: #dbdbdb;
    display: flex;
    align-items: stretch;
}

/* Details box */
.details-box-default-container {
    width: 100%;
    padding: 0 15px 15px 15px;
}

.details-box-default-container-columns[data-count='1'] > div {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.details-box-default-container-columns[data-count='1'] > div > div:not(:last-child) {
    margin-bottom: 15px;
}

.details-box-default-container-columns[data-count='2'] > div {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.details-box-default-container-columns[data-count='2'] > div > div:not(:last-child) {
    margin-bottom: 8px;
}

.details-box-default-container-columns[data-count='3'] > div {
    width: calc(100% / 3);
    display: inline-block;
    vertical-align: top;
}

.details-box-default-container-columns[data-count='3'] > div > div:not(:last-child) {
    margin-bottom: 8px;
}

.details-box-default-container-item {
    display: flex;
    gap: 10px;
    align-items: self-start;
}

.details-box-default-container-item label {
    font-weight: bold;
    font-size: 14px;
    color: #1b1b1b;
}

.details-box-item-field-value {
    font-size: 14px;

    color: #1b1b1b;
    min-height: 14px;
    word-break: break-word;
}

.details-box-item-field-icon {
    font-size: 15px;
    color: var(--main-orange-color);
    cursor: pointer;
}
.details-box-item-field-gray-icon {
    font-size: 15px;
    color: var(--main-orange-color);
    cursor: pointer;
}
.details-box-item-field-gray-icon:hover {

    color: var(--main-orange-color);
}
.details-box-item-field-edit-value {
    font-size: 14px;

    color: var(--main-orange-color);
    min-height: 14px;
    cursor: pointer;
}


.form-tag > button {
    font-size: 10px;

    text-transform: uppercase;
}

