.auction {
    width: 12pt;
    vertical-align: bottom;
    margin-left: 5px;
}

.rejectedMark {
    width: 10px;
    height: 28px;
    background-color: #c9302c;
    display: block;
    border-radius: 1px;
    margin-right: 5px;
}

.readByMark {
    width: 30px;
    height: 28px;
    color: var(--light-blue-color);
    display: block;
    border-radius: 1px;
    margin-right: 5px;
    cursor: pointer;
}

.readByMarkIcon {
    width: 12px;
    height: 28px;
    color: var(--light-blue-color);
    display: inline;
    border-radius: 1px;
}

.readByMarkCount {
    position: absolute;
}
