.item {
    width: 20%;
    vertical-align: top;
    position: relative;
    color: #969696;
    display: flex;
    flex-direction: column;
}

.passed {
    color: #4a4a4a;
}

.last {
    border-right: 2px solid #969696;
}

.first {
    border-left: 2px solid #969696;
}

.data {
    margin: 15px 5px 15px 10px;
}

.label {
    display: flex;
}

.address {
    font-size: 12px;
    color: inherit;
    font-weight: 700;
}

.passed {
    color: #4a4a4a;
}

.typeIcon {
    margin-right: 5px;
    font-size: 13px;
}

.typeIcon[data-sp-type='[P]'] {
    color: var(--main-orange-color);
}

.typeIcon[data-sp-type='[D]'] {
    color: #7FB98B;
}

.date {
    font-weight: 700;
    color: #969696;
    font-size: 12px;
}

.factDate {
    font-weight: 700;
    font-size: 12px;
}

.etaDate {
    display: block;
    font-size: 12px;
    font-weight: bold;
}

.etaShift {
    padding: 2px;
    border-radius: 2px;
    color: white;
    width: max-content;
    display: block;
    font-size: 12px;
}


.circle {
    width: 38px;
    height: 38px;
    border: 6px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    left: -1px;
}

.circle[data-passed='p'], .line[data-passed='p'] {
    background-color: #7FB98B;
    border-color: #7FB98B;
}

.circle[data-passed='np'] {
    border-color: #b6b6b6;
}

.line {
    height: 6px;
    width: 100%;
    background-color: #b6b6b6;
    margin-left: 36px;
}

.line[data-type='None'] {
    width: 100%;
    display: none;
}
