.auctionIcon {
    width: 18px;
    margin: 0 5px;
}

.firstOrder {
    color: var(--light-blue-color);
    font-size: 13px;
    margin-right: 5px;
}

.transEu {
    width: 25px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 2px;
}

.rejectedMark {
    width: 10px;
    height: 28px;
    background-color: #c9302c;
    display: block;
    border-radius: 1px;
    margin-right: 5px;
}

.user {
    color: #4e4e4e;
    font-size: 13px;
    margin-right: 5px;
}

.eyeContainer {
    margin-right: 5px;
    font-size: 8px;
    cursor: pointer;
}

.eye {
    color: var(--main-orange-color);
    font-size: 11px;
}
.readByMark {
    width: 30px;
    height: 28px;
    color: var(--light-blue-color);
    display: block;
    border-radius: 1px;
    margin-right: 5px;
    cursor: pointer;
}

.readByMarkIcon {
    width: 12px;
    height: 28px;
    color: var(--light-blue-color);
    display: inline;
    border-radius: 1px;
}

.readByMarkCount {
    position: absolute;
}
