.label {
    margin-bottom: 5px;
    font-size: 13px;
    display: flex;
}

.address {
    font-size: 12px;
    color: inherit;
    font-weight: 700;
}

.typeIcon {
    margin-right: 5px;
    font-size: 13px;
}

.typeIcon[data-sp-type='[P]'] {
    color: var(--main-orange-color);
}

.typeIcon[data-sp-type='[D]'] {
    color: #7FB98B;
}
