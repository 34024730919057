.alert {
    display: flex;
    align-items: center;
    gap: 10px;
}

.alertBtn {
    background-color: inherit;
    border: 1px solid black;
    font-weight: bold;
    cursor: pointer;
    color: black;
    font-size: 11px;
    border-radius: 2px;
    padding: 1px 5px;
}

.container {
    display: flex;
    gap: 20px;
}

.container > div {
    width: 50%;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column;
        gap: 0;
    }

    .container > div {
        width: 100%;
    }
}

.row {
    height: 60px;
    margin-top: 10px;
}

.btnContainer {
    padding: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

}

