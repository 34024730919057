.container {
    padding: 10px;
}

.container > div {
    display: flex;
    margin-bottom: 10px;
}

/*
.container > div:nth-child(even) {
    flex-flow: row-reverse;
}*/
