.link {
    transition: all 500ms ease-in;
    text-decoration: none;
}

.button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 500ms ease-in;
    text-decoration: none;
}

.button[data-type='primary'] {
    height: 35px;
}

.button[data-type='secondary'] {
    height: 35px;
}

.button:hover {
    background-color: #2D364A;
}

.button.active {
    background-color: #2D364A;
}

.disabled {
    cursor: not-allowed;
}

.text {
    margin-left: 51px;
    color: #F6F6F6;
    font-size: 12px;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0;
    visibility: hidden;
}

.active .text {
    color: #E06235;
}

.text[data-expanded='expanded'] {
    animation: appear 1s ease forwards;
    animation-delay: 0.1s;
}

@keyframes appear {
    20% {
        opacity: 0.20;
        visibility: visible;
        max-width: fit-content
    }
    30% {
        opacity: 0.30
    }
    40% {
        opacity: 0.40
    }
    50% {
        opacity: 0.50
    }
    60% {
        opacity: 0.60
    }
    70% {
        opacity: 0.70
    }
    80% {
        opacity: 0.80
    }
    90% {
        opacity: 0.90
    }
    100% {
        opacity: 1;
        visibility: visible;
        max-width: fit-content
    }
}
