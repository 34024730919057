.footer {
    backdrop-filter: blur(3px) saturate(15%);
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    user-select: none;
}

.footer > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.footer > span:nth-child(2) {
    font-weight: bold;
}