.container {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    padding: 0 20px;
    align-items: center;
}

.container > div:first-child {
    font-weight: bold;
    font-size: 14px;
    color: black;
}

.tag > button {
    font-size: 10px;
    text-transform: uppercase;

}

.price {
    margin-left: 5px;

}
