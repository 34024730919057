.container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 15px;
    row-gap: 10px;
    border-bottom: 1px solid #dbdbdb;
}

.container label {
    font-weight: bold;
    font-size: 14px;
    color: #1b1b1b;
}

.container > div > span {
    font-size: 14px;

    color: #1b1b1b;
    min-height: 14px;
    word-break: break-word;
}
