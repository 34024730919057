.item {
    border-bottom: 1px solid rgb(234, 239, 243);
    margin: 5px;
    border-radius: 2px;
    padding: 5px;
    min-height: 150px;
    overflow: hidden;
}

.error {
    font-size: 11px;
    font-weight: bold;
    margin: 5px 0;
}
