.container {
    margin: 15px 5px;
    min-height: 30px;
    border-radius: 2px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    user-select: none;
    text-align: center;
}

.container[data-type='Error'] {
    border: 1px solid #EE2012;
    color: #EE2012;
}

.container[data-type='YellowInfo'] {
    border: 1px solid #E5C51A;
    color: #E5C51A;
}

.container[data-type='BrightGrayInfo'] {
    color: #7D89BA;
    border: 1px solid #7D89BA;
}

.container[data-type='Success'] {
    border: 1px solid #7FB98B;
    color: #7FB98B;
}

.container[data-type='OrangeInfo'] {
    border: 1px solid #e96024;
    color: #e96024;
}
