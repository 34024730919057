.wrapper input[type='checkbox'] {
    --border: #DBDBDB;
    --background: #fff;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    outline: none;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--border);
    background: var(--background);
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    border-radius: 2px;
}

.wrapper input[type=checkbox]:after {
    content: "";
    display: block;
    position: absolute;
    transition: 0.3s ease, opacity 0.2s;

    width: 5px;
    height: 9px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(20deg);
}

.wrapper input[type=checkbox]:checked {
    border-color: #3F8CE5;
    background: #3F8CE5;
    transition: transform 0.6s cubic-bezier(.2, .85, .32, 1.2), opacity 0.3s;
}

.wrapper input[type='checkbox']:checked:after {
    transform: rotate(43deg);
}

.wrapper input[type=checkbox] + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    color: #292929;
    margin-left: 4px;
}

.wrapper input[type=checkbox]:disabled {
/*    background-color: #F6F8FF;*/
    cursor: not-allowed;
    opacity: 0.9;
}

.wrapper input[type=checkbox]:disabled:checked {
 /*   background-color: #E1E6F9;
    border-color: var(--border);*/
}

.wrapper input[type=checkbox]:disabled + label {
    cursor: not-allowed;
}

.wrapper input[type=checkbox]:hover:not(:checked):not(:disabled) {
    border-color: #3F8CE5;
}


.big input[type='checkbox'] {
    width: 21px;
    height: 21px;
}

.big input[type=checkbox]:after {
    left: 6px;
    top: 1px;

    width: 8px;
    height: 14px;
}

.big input[type=checkbox] + label {
    font-size: 14px;
}

.small input[type='checkbox'] {
    width: 15px;
    height: 15px;
}

.small input[type=checkbox] + label {
    font-size: 13px;
}

.small input[type=checkbox]:after {
    left: 4px;
    top: 1px;
}

.medium input[type='checkbox'] {
    width: 16px;
    height: 16px;
}

.medium input[type=checkbox]:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
}

.medium input[type=checkbox] + label {
    font-size: 14px;
}

.sm {
    width: 50%;
}

.sm input[type='checkbox'] {
    width: 16px;
    height: 16px;
}

.sm input[type=checkbox]:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
}

.sm input[type=checkbox] + label {
    font-size: 13px;
}

.wrapper * {
    box-sizing: inherit;
}

.wrapper *:before,
.wrapper *:after {
    box-sizing: inherit;
}
