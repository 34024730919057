.headerContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.headerContainer > div:first-child {
    width: 25px;
    white-space: nowrap;
}

.trash {
    color: #2c2c2c;
    font-size: 15px;
    cursor: pointer;
}

.trash:hover {
    color: var(--red-color);
}
