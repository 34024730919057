.container {
    display: flex;
    flex-direction: column;
}

.container > div:last-child {
    flex: 1;
}

.header {
    font-size: 15px;
    padding: 5px 0;
    font-weight: bold;
}

.content {
    display: grid;
    grid-template-columns: calc(50% - 1px) 2px calc(50% - 1px);
}

.cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3em;
    overflow: auto;
}

@media (max-width: 1024px) {
    .content {
        grid-template-columns: none;
        grid-template-rows: calc(50% - 1px) 2px calc(50% - 1px);
    }
}

.separator {
    background-color: var(--main-bright-gray-color);
}