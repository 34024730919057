.listBox {
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    overflow: hidden;
    display: grid;
}

.listBox[data-type='si'] {
    grid-template-rows: 35px calc(100% - 35px);
}

.listBox[data-type='default'] {
    grid-template-rows: 100%;
}

.searchContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0 5px;
    /*box-shadow: 0 5px 2px -2px rgba(0, 0, 0, 0.2);*/
    border-bottom: 1px solid #d8d8d8;

    position: relative;
}

.search {
    width: 100%;
    height: 100%;
    padding: 3px 25px 3px 4px;
    font-size: 13px;
    cursor: pointer;
    border: none;
    color: #555;
    border-radius: 2px;
}

.search:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.search::placeholder {
    color: #959595;
    font-size: 12px;
}

.search:disabled {
    cursor: not-allowed;
    background: white;
    color: rgba(0, 0, 0, .8);
}

.glass {
    position: absolute;
    right: 10px;
    color: #A0A0A0;
    font-size: 14px;
}

.noData {
    text-align: center;
    font-size: 12px;
    padding: 10px 5px;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderContainer > div {
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 2px;
    position: relative;
}

.row {
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.row:hover {
    background-color: var(--main-bright-gray-color);
}

.row > div:first-child {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row > div:last-child {
    width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selected {
    color: var(--main-blue-color);
    background-color: var(--main-bright-gray-color);;
}

.groupRow {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 5px;
}
