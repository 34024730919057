.button {
    width: 32px;
    min-width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    color: black;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;


    -webkit-animation: appear 0.5s ease-out;
    -moz-animation: appear 0.5s ease-out;
    -o-animation: appear 0.5s ease-out;
    animation: appear 0.5s ease-out;
}

.button svg {
    font-size: 16px;
}

body:global(:not(.mobile)) .button:hover {
    color: #e96024;
    border-color: #e96024;
}

.toggle {
    color: #e96024;
    border-color: #e96024;
}

.button:disabled, .disabled {
    cursor: not-allowed;
}

.button input {
    position: absolute;
    display: none;
    color: #fff !important;
}


@-moz-keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}


.postIconText {
    font-size: 9px;
    font-weight: bold;
    bottom: 0;
    position: absolute;
}

.iContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}