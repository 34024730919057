.container {
    margin: 10px 0;
    transition: all 0.4s;
}

.container[data-expanded='expanded'] {
    padding: 0 20px;
}

.container[data-expanded='condensed'] {
    padding: 0 9px;
}

.comboboxItem {
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comboboxItem > span:first-child {
    color: var(--main-orange-color);
    margin-right: 5px;
}

.comboboxItem > span:nth-child(2) {
    color: #4a4a4a;
}

.comboboxItem img {
    width: 17px;
    height: 17px;
    margin-left: 5px;
}

.glass {
    color: #70757F;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 13px;
    right: 8px;
}

.glassCondensed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}