.loaderContainer {
    width: 150px;
    height: 70px;
    background-color: white;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    color: #4a4a4a;
    font-size: 13px;
}