.button {
    min-width: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 500ms ease-in;
    text-decoration: none;
}

.button[data-type='primary'] {
    height: 40px;
}

.button[data-type='secondary'] {
    height: 35px;
}

.text[data-display='flex'] {
    display: flex;
    align-items: center;
    gap: 7px;
}

.button:hover {
    background-color: #1D273D;
}

.button.active {
    background-color: #1D273D;
    box-shadow: inset 2px 0 0 0 var(--main-orange-color);
}

.disabled {
    cursor: not-allowed;
}

.active .icon {
    color: #E06235;
}

.icon {
    color: #677188;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px 0 20px;
}

.text {
    color: #F6F6F6;
    font-size: 14px;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0;
    visibility: hidden;
}

.active .text {
    color: #E06235;
}

.text[data-expanded='expanded'] {
    animation: appear 1s ease forwards;
    animation-delay: 0.1s;
}

@keyframes appear {
    20% {
        opacity: 0.20;
        visibility: visible;
        max-width: fit-content
    }
    30% {
        opacity: 0.30
    }
    40% {
        opacity: 0.40
    }
    50% {
        opacity: 0.50
    }
    60% {
        opacity: 0.60
    }
    70% {
        opacity: 0.70
    }
    80% {
        opacity: 0.80
    }
    90% {
        opacity: 0.90
    }
    100% {
        opacity: 1;
        visibility: visible;
        max-width: fit-content
    }
}

.chevron {
    position: absolute;
    right: 20px;
    color: #677188;
    font-size: 5px;
    transition: all 500ms;
}

.nested {
    padding-left: 2px;
    transition: all 500ms ease-in;
}

.nested.active {
    background-color: #1D273D;
    box-shadow: inset 2px 0 0 0 var(--main-orange-color);
}
