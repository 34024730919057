.card {
    width: 100%;
    height: 45px;
    border-radius: 2px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 45px calc(100% - 45px - 30px) 30px;
    padding: 0 5px 0 0;
}

.card > div:first-child {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 1px;
}

.card > div:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
}