.input {
    width: 100%;
    height: 40px;
    padding: 3px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #D8D8D8;
    color: #4E4E4E;
    border-radius: 2px;
}

.input:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.input::placeholder {
    color: #959595;
    font-size: 10px;
}

.input:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}


.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.iconContainer {
    height: 30px;
    width: 40px;
    border: 1px solid #D8D8D8;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: none;
    background-color: white;
}

.iconContainer img {
    width: 18px;
}

.iconContainer svg {
    color: var(--main-orange-color);
}

.container input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
