.container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    overflow: auto;
    margin: 10px 10px 5px 10px;
}

.container > div {
    text-align: center;
    font-size: 11px;
    padding: 1px 5px;
    white-space: nowrap;
    border: 1px solid var(--main-blue-color);
    margin: 0 2px 2px 0;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    height: 21px;
    border: 1px solid rgba(0, 29, 100, .4) !important;
    padding: 3px 5px !important;
}

.selected {
    border-color: var(--main-blue-color) !important;
}

.img {
    width: 16px;
}