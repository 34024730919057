.container {
    position: absolute;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;
}

.render {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    display: flex;
}

.contextBtn svg {
    font-size: 16px;
}