.switcher {
    padding: 5px;
    display: flex;
    justify-content: start;
}

.logo {
    margin: 20px 0;
    text-align: center;
}

.logo > img {
    width: 40%;
}

.label {
    text-align: center;
    font-weight: 400;
    color: #4a4a4a;
    margin: 10px 0 0 0;
    font-size: 20px;
}

.separator {
    margin: 10px 0;
    border: 0.5px solid #D8D8D8;
}

.linkBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.linkBtns > button {
    color: #999;
    font-size: 12px;

    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    border: none;
}

.linkBtns > button:hover {
    color: var(--main-orange-color);
}

.linkBtns > button:disabled {
    cursor: not-allowed;
}

.center {
    text-align: center;
}
