.columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 5px;
    overflow: auto;
}

.buttons {
    padding: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
}


.column {
    margin: 2px;
    height: 35px;
    color: black;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
    display: grid;
    grid-template-columns: 100px calc(100% - 100px);
}

.columnText {
    display: flex;
    padding: 0 5px 5px;
    align-items: center;
}

.checkboxContainer {
    display: flex;
   /* margin-right: 10px;*/
}

.checkboxContainer > div {
    width: 50%;
    display: flex;
    align-items: center;

    justify-content: center;
    gap: 3px;
}

.checkboxContainer svg {
    font-size: 11px;
    color: grey;
}

.pd5 {
    padding: 5px;
}
