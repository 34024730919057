.backgroundImageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: var(--bg-color);

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}