.chevron {
    color: white;
    font-size: 18px;
    cursor: pointer;

    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: all 500ms;
}

.chevron[data-custom-value-attr='condensed'] {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.logo {
    color: white;
    font-size: 23px;
    cursor: pointer;

    opacity: 0;
    visibility: hidden;
    width: 0;
}

.logo[data-custom-value-attr='expanded'] {
    animation: logoAppear 1s ease forwards;
    animation-delay: 0.2s;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes logoAppear {
    20% {
        opacity: 0.20;
        visibility: visible;
        width: fit-content
    }
    30% {
        opacity: 0.30
    }
    40% {
        opacity: 0.40
    }
    50% {
        opacity: 0.50
    }
    60% {
        opacity: 0.60
    }
    70% {
        opacity: 0.70
    }
    80% {
        opacity: 0.80
    }
    90% {
        opacity: 0.90
    }
    100% {
        opacity: 1;
        visibility: visible;
        width: fit-content
    }
}
