.button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: white;
    border-radius: 2px;
    color: #1b1b1b;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;


    -webkit-animation: appear 0.5s ease-out;
    -moz-animation: appear 0.5s ease-out;
    -o-animation: appear 0.5s ease-out;
    animation: appear 0.5s ease-out;
}

body:global(:not(.mobile)) .button:hover {
    color: var(--main-orange-color);
    border-color: var(--main-orange-color);
}

.toggle {
    color: var(--main-orange-color);
    border-color: var(--main-orange-color);
}

.button:disabled, .disabled {
    cursor: not-allowed;
}

.button input {
    position: absolute;
    display: none;
    color: #fff !important;
}


@-moz-keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5
    }

    100% {
        opacity: 1;
    }
}
