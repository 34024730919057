.container {
    display: flex;
    justify-content: space-between;
    height: 25px;
    margin-bottom: 5px;
}

.gid {
    font-weight: bold;
    color: var(--main-orange-color);
}

.gidContainer {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 5px;
}

.gidContainer img {
    width: 15px;
}

.label {
    font-weight: bold;
    color: #4a4a4a;
    margin-left: 10px;
}

.close {
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    opacity: .4;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.close:hover {
    opacity: .8;
}

.btn {
    border: none;
    background: inherit;
    font-size: 12px;
    color: var(--main-orange-color);
    cursor: pointer;
}

.btn:hover {
    text-decoration: underline;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.hasTrackingTooltip {
    font-size: 14px;
    display: block;
    font-weight: bold;
    color: red;
    margin-bottom: 5px;
}