.tab {
    height: 28px;
    cursor: pointer;
    margin-right: 5px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    border-radius: 20px;
    border: 1px solid white;
    position: relative;
    transition: all 500ms ease;
    z-index: 1;
}

.tab > div {
    margin-bottom: 1px;
}

.tab:hover {
    border-color: #1B3274;
}

.active {
    background-color: #1B3274;
    border-color: #1B3274;
    color: white;
}

.tab.active::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: -1;
    background: linear-gradient(to right bottom, transparent 0%, transparent 73%,#1B3274 50%,#1B3274 50%,#1B3274 100%);
}