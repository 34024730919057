.container {
    height: 98vh;
    display: grid;
    grid-template-rows: 40px calc(100% - 40px);
}

.header {
    display: grid;
    grid-template-columns: calc(100% - 30px) 30px;
    padding-left: 5px;
    border-bottom: 1px solid var(--main-orange-color);
}

.header > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.close {
    cursor: pointer;
    font-size: 21px;
    font-weight: bold;
    color: #000;
    opacity: .4;
    margin: auto 0;
}

.close:hover {
    opacity: .8;
}
