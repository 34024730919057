.container[data-type='two'] {
    padding: 10px;
    display: flex;
    gap: 5px;
}

.container[data-type='two'] > div {
    width: 50%;
}

.container[data-type='four'] {
    padding: 10px;
    display: flex;
    gap: 5px;
}

.container[data-type='four'] > div {
    width: 25%;
}

@media screen and (max-width: 1024px) {
    .container[data-type='two'], .container[data-type='four'] {
        display: block;
        width: 100%;
    }

    .container[data-type='two'] > div, .container[data-type='four'] > div {
        margin-bottom: 10px;
        width: 100%;
    }
}

.collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 27px;
}

.collapse > div:first-child {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin-bottom: 2px;
}

.collapse > div:first-child > div:last-child {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon {
    color: var(--main-orange-color);
    font-size: 14px;
}

.tg {
    color: #049be5;
    font-size: 14px;
}

.viber {
    color: #8e24aa;
    font-size: 14px;
}

.qr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr > div {
    width: 150px;
    height: 150px;
}

.button {
    background-color: #c63434;
    color: white;
    border: navajowhite;
    border-radius: 2px;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
}

.paragraph {


    font-size: 14px;
    margin-bottom: 10px;
}

.link {
    color: #E06235;
    text-decoration: none;
}

.tooltip {
    color: #cccccc;
    font-size: 13px;
    margin-left: 5px;
    vertical-align: bottom;
}

.tooltip:hover {
    color: black;
}

.checkboxRow {
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.checkboxRow label {
    white-space: normal;
    color: #4a4a4a;
}
