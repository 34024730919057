.collapse {

}

.header {
    height: 40px;
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
    border-top: 1px solid var(--main-orange-color);
    padding: 0 20px;
}

.icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    font-size: 6px;
    color: #1b1b1b;
    transition: all 500ms;
}

.title {
    font-size: 14px;
    color: var(--main-orange-color);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.content {

}
