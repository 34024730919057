.container {
    display: grid;
    grid-template-rows: 45px 110px calc(100% - 155px);
}

.buttons {
    display: flex;
    padding: 5px;
    justify-content: space-between;
}

.buttons > div:last-child {
    display: flex;
    gap: 5px;
}

.info {
    margin: 5px;
    border: 1px solid var(--main-bright-gray-color);
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.info > div {
    width: 33.3%;
    padding: 0 20px;
}

.info > div > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
}

.info > div > div > span {
    flex: 1;
    width: 100%;
    align-items: center;
    display: flex;
}