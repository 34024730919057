.chevron {
    font-size: 6px;
    color: #1b1b1b;
    transition: all 500ms;
}

.header > div:first-child {
    height: 40px;
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
    padding: 0 20px;
}

.header > div:first-child > div:first-child {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #1b1b1b;
    gap: 15px;
}

.header > div:first-child > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btns {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
}
.btnslong {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.button {
    background-color: white;
    color: #7FB98B;
    border-radius: 2px;
    border: 1px solid #7FB98B;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    height: 27px;
    margin-right: 15px;
}

.button svg {
    font-size: 14px;
}

.gid {
    margin-left: 5px;
}

.gidActive {
    cursor: pointer;
    color: var(--main-orange-color);
}

.tag > button {
    font-size: 10px;
    text-transform: uppercase;

}

.alert {
    margin-bottom: 15px;
    padding: 0 20px;
}

.alert > div {
    font-size: 12px;

    margin: 0;
    width: fit-content;
}

/*
.container {
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 2px;
    margin-bottom: 5px;
}

.header {

}

.header > div:first-child {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
    min-height: 26px;
}

.header > div:first-child > div:first-child {
    display: flex;
    align-items: center;
    padding: 3px 5px;
    justify-content: space-between;
}

.header > div:first-child > div:last-child {
    color: lightgray;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.icon {
    font-size: 12px;
    color: var(--main-blue-color);
}

.hCollapse {
    display: flex;
    align-items: center;
    gap: 5px;
}

.hAction {
    display: flex;
    gap: 5px;
}

.hActionRow {
    justify-content: space-between;
    display: flex;
    margin: 0px 5px;
}


.button {
    background-color: var(--main-orange-color);
    color: white;
    border: none;
    border-radius: 2px;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    max-height: 20px;
}

.button svg {
    font-size: 12px;
}

.button div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 840px) {
    .button > div {
        display: none;
    }
}

.content {
    border-top: 1px solid var(--main-bright-gray-color);
}

.gid {
    margin-left: 5px;
}

.gidActive {
    cursor: pointer;
    color: var(--main-orange-color);
}
*/
