.loaderOverlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    z-index: 2;
    cursor: not-allowed;
}

.loaderContainer {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    background-color: rgba(234, 239, 243, 0.6);
    cursor: progress;
}

.loaderContainer > div {
    -webkit-transform: translateX(-50%) translateY(-50%);
    left: 50%;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1000;

    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 2px;
}

.hidden {
    display: none;
    background: transparent;
}