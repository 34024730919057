.container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    overflow: auto;
    margin: 10px 10px 5px 10px;
}

.container > div {
    font-size: 11px;
    padding: 1px 5px;
    white-space: nowrap;
    border: 1px solid var(--main-blue-color);
    margin: 0 2px 2px 0;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
}