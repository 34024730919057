.languageSwitcherContainer {
    padding: 5px;
    display: flex;
    justify-content: end;
}

.logoContainer {
    margin: 20px 0;
    text-align: center;
}

.logoContainer > img {
    width: 50%;
}

.loginLabel {
    text-align: center;
    font-weight: 400;
    color: #4a4a4a;
    margin: 10px 0 0 0;
    font-size: 20px;
}