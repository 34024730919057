.cell {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 30px;
    line-height: 15px;
    text-align: left !important;
    margin: 0 10px;
}

.editCell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: end;
    padding: 3px;
}

.editCell {
    font-size: 7px;
    color: var(--main-blue-color);
}