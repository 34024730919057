/* so  dropdowns show on modal dialogs */
.k-animation-container {
    z-index: 10003 !important;
}

.grid-filter .k-animation-container {
    z-index: 999 !important;
}

.k-popup > .k-list .k-list-item {
    color: #111C35;
    font-size: 12px;
}

.k-popup > .k-list .k-list-item:hover {
    background-color: #EFEFEF;
    color: #111C35;
}

.k-popup > .k-list .k-selected {
    color: #111C35;
    background-color: #EFEFEF;
    box-shadow: none;
}

.picker .k-input-outline {
    position: relative;
    border-color: #D8D8D8;
    background-color: white !important;
    flex-flow: row-reverse;
}

.picker .k-input-button {
    background-color: white;
    border-inline-start-width: 0;
    border-inline-end-width: 1px;
}

.picker .k-input-button svg {
    color: var(--main-orange-color);
}

.picker .k-input-outline:hover, .k-input-outline.k-hover {
    border-color: #D8D8D8 !important;
}

.picker .k-input-outline:focus, .k-input-outline.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.picker .k-input-outline:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.picker .k-input-inner {
    font-size: 13px;
    /*pointer-events: none;*/
    color: #555;
}

.picker .k-input-inner::placeholder {
    color: darkgray;
    font-size: 12px;
}

/* DropDownList */

#shipment-grid-filter-panel .k-combobox input::placeholder {
    color: #959595;
    font-size: 10px;
    line-height: 1;
    left: 6px;
}

#shipment-grid-filter-panel .k-input-solid {
    border: 1px solid #d8d8d8;
}

#shipment-grid-filter-panel .k-combobox input {
    height: 33px;
}

#shipment-grid-filter-panel .k-input-button {
    border-inline-start-width: 0px;
}

#shipment-grid-filter-panel .k-button-solid-base {
    background-color: transparent;
    background-image: none;
}

#shipment-grid-filter-panel .k-picker-solid {
    border-color: #D8D8D8;
    background-color: white !important;
    border-radius: 2px;
    height: 32px;
    background-image: none;
}

#shipment-grid-filter-panel .k-picker-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.mobile-picker.drop-down-picker .k-picker-solid {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.drop-down-picker .k-picker-solid {
    border-color: #D8D8D8;
    background-color: white !important;
    border-radius: 2px;
    height: 40px;
}

.drop-down-picker .k-picker-solid:focus, .k-picker-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.drop-down-picker .k-input-inner {
    font-size: 14px;
    color: #555;
}

/* ComboBox */

.combobox-picker .k-input-solid {
    border-color: #D8D8D8;
    background-color: white !important;
    border-radius: 2px;
    height: 40px;
}

.combobox-picker .k-input-solid:hover, .combobox-picker .k-input-solid.k-hover {
    border-color: #D8D8D8 !important;
}

.combobox-picker .k-input-solid:focus, .combobox-picker .k-input-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.combobox-picker .k-input-solid:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.combobox-picker .k-input-inner {
    font-size: 13px;
    color: #555;
}

.combobox-picker .k-input-inner::placeholder {
    color: darkgray;
    font-size: 12px;
}

.k-list-md .k-list-item {
    font-size: 12px;
}

.k-radio:checked, .k-radio.k-checked {

}

.radio-buttons-container .k-radio-label {
    font-size: 12px;
    color: #969696;
    font-weight: bold;
}

.radio-buttons-container .k-radio:focus, .radio-buttons-container .k-radio.k-focus {
    box-shadow: none !important;
}

.radio-buttons-container .k-radio {
    border-color: #878787;
}

.radio-buttons-container .k-radio:checked, .radio-buttons-container .k-radio.k-checked {
    background-color: var(--main-orange-color);
    border-color: var(--main-orange-color);
}

/* Masked input */

.masked-input .k-input-solid {
    width: 100%;
    height: 40px;
    padding: 3px 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #D8D8D8;
    color: #555;
    border-radius: 0 2px 2px 0;
}

.masked-input .k-input-solid:hover, .masked-input .k-input-solid.k-hover {
    border-color: #D8D8D8 !important;
}

.masked-input .k-input-solid:focus, .masked-input .k-input-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.masked-input .k-input-solid:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}


.numeric-text-box .k-input, .numeric-text-box .k-picker {
    height: 40px;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    font-size: 13px;
    font-family: inherit;
    color: #555;
}

.numeric-text-box .k-input-inner {
    padding: 3px 4px;
}

.numeric-text-box .k-input-inner::placeholder {
    color: darkgray;
    font-size: 12px;
}

.numeric-text-box .k-input-solid:focus, .numeric-text-box .k-input-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.numeric-text-box .k-input-solid:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

/* Filter date input */
.filter-date-picker .k-input {
    overflow: visible !important;
}

.filter-date-picker .k-input-outline {
    height: 35px !important;
    border-radius: 3px;
}

.filter-date-picker .k-input-inner {
    font-size: 12px !important;
    pointer-events: none !important;
}

.filter-date-picker .k-input-button svg {
    width: 14px !important;
    height: 14px !important;
    color: #677188;
}

.filter-date-picker .k-input-outline {
    border-color: #d8d8d8;
    background-color: white !important;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.filter-date-picker .k-input-button {
    background-color: white;
    background-image: none;
    border-inline-start-width: 0;
    border-inline-end-width: 0;
}

.filter-date-picker .k-button-solid-base:hover, .filter-date-picker .k-button-solid-base.k-hover {
    background-color: white;
}

.filter-date-picker .k-input-outline:hover:not(:focus):not(:focus-within), .k-input-outline.k-hover:not(.k-focus):not(:focus-within) {
    border-color: #d8d8d8 !important;
}

.filter-date-picker .k-input-outline:focus, .k-input-outline.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.filter-date-picker .k-input-outline:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.filter-date-picker .k-input-inner {
    font-size: 12px;
    color: #555;
}

.picker .k-input-inner::placeholder {
    color: #959595;
    font-size: 12px;
}


/* Tree list box */

.tree-list-box .k-treeview-item {
    font-size: 12px;
    cursor: pointer;

}

.tree-list-box .k-treeview {
    color: #292929;
    background-color: white;
}

.tree-list-box .k-treeview-leaf {
    padding-block: 0 !important;
    padding-inline: 2px 2px !important;
}

.tree-list-box .k-treeview-leaf:hover {
    color: black;
    background-color: var(--main-bright-gray-color);
}

.tree-list-box .k-treeview-top:hover, .tree-list-box .k-treeview-mid:hover, .tree-list-box .k-treeview-bot:hover {
    background-color: var(--main-bright-gray-color);
}

.tree-list-box .k-treeview-leaf:focus, .tree-list-box .k-treeview-leaf.k-focus {
    box-shadow: none !important;
}

.tree-list-box .k-treeview-md .k-checkbox-wrap {
    width: 25px;
    align-items: center;
    justify-content: center;
}

.tree-list-box .k-checkbox-md {
    border: 1px solid #DBDBDB;
    border-radius: 2px;
    width: 15px;
    height: 15px;

    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

.tree-list-box .k-checkbox:focus {
    box-shadow: none !important;
}

.tree-list-box .k-checkbox-md:hover {
    border-color: #3F8CE5;
}

.tree-list-box .k-checkbox:checked {
    border-color: #3F8CE5;
    background-color: #3F8CE5;
}

.tree-list-box .k-treeview-top, .tree-list-box .k-treeview-mid, .tree-list-box .k-treeview-bot {
    height: 25px;
}

.tree-list-box .k-treeview-item .k-animation-container {
    overflow: inherit;
}


/* Collor palette */
.k-colorpalette-table {
    border-spacing: 2px;
    border-collapse: unset !important;
}


/* Global search combobox */

#global-search-header-item .k-input-solid {
    border-radius: 2px;
    height: 25px;
}

#global-search-header-item .k-button-md.k-icon-button {
    display: none;
}

.file-upload .k-upload {
    border-color: #ebecf0;
    border-radius: 2px;
}

.file-upload .k-button-solid-base {
    background-color: #7FB98B;
    border-radius: 2px;
    color: white;
    font-size: 12px;
}

.file-upload .k-button-solid-base:hover {
    background-color: #7FB98B;
}

.file-upload .k-upload .k-dropzone, .file-upload .k-upload .k-upload-dropzone {
    background-color: #D8D8D8;
    color: #828282;
}


.file-upload .k-upload .k-upload-status {
    font-size: 12px;
}

.file-upload .k-upload .k-dropzone .k-dropzone-hint, .file-upload .k-upload .k-upload-dropzone .k-dropzone-hint {
    font-size: 12px;
}


/* MultiSelect tag styles */

.multi-select .k-chip-md {
    padding: 0;
    gap: 0;
    padding-inline: 0 !important;
    padding-block: 0 !important;
    border: none !important;
    background-color: white !important;
}

.multi-select .k-input-solid {
    border-color: #D8D8D8;
    background-color: white !important;
    border-radius: 2px;
    max-height: 30px;
    font-size: 13px;
    color: #555;
}

.multi-select .k-input-solid:focus, .multi-select .k-input-solid.k-focus {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.multi-select .k-clear-value {
    display: none;
}


/* Global search combobox */

.global-search-combobox-picker .k-input-solid {
    border: none;
    background-color: #2D3953 !important;
    border-radius: 4px;
    height: 40px;
    transition: all 0.4s;
}

.global-search-combobox-picker .k-input-solid:hover, .global-search-combobox-picker .k-input-solid.k-hover {
    border: none !important;;
}

.global-search-combobox-picker .k-input-solid:focus, .global-search-combobox-picker .k-input-solid.k-focus {
    box-shadow: none;
    outline: none;
}

.global-search-combobox-picker .k-input-solid:focus-within {
    box-shadow: none;
    outline: none;
}

.global-search-combobox-picker .k-input-inner {
    font-size: 13px;
    color: #F6F6F6;
    padding-inline: 8px 30px;
}

.global-search-combobox-picker .k-input-inner::placeholder {
    color: #70757F;
    font-size: 13px;
}

.global-search-combobox-picker .k-input-md .k-input-button {
    display: none;
}

.global-search-combobox-picker[data-expanded='condensed'] .k-input-inner::placeholder {
    display: none;
}

.k-timepicker .k-button {
    display: none;
}
