:global(.react-datepicker-wrapper) {
    width: 100%;
}

:global(.react-datepicker-popper) {
    z-index: 10000;
}

.input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 14px;
    color: #555;
    padding: 3px 26px 3px 4px;
    background-color: white;
}

.input::placeholder {
    color: #959595;
    font-size: 12px;
}

.error {
    outline: 0.05rem solid red !important;
}

.ici {
    padding-right: 45px;
}

.disabled {
    cursor: not-allowed;
    background-color: white;
    color: #7f7f7f;
}

.input:focus-within {
    box-shadow: none;
    outline: none;
    border-color: var(--main-blue-color);
}

.big {
    height: 40px;
}

.small {
    height: 30px;
    font-size: 13px;
}

.medium {
    height: 35px;
}

.calendar {
    width: 14px;
    height: 14px;
    color: #677188;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    z-index: 1;
    cursor: pointer;
}

.calendarBig {
    width: 16px;
    height: 16px;
}

.calendarSmall {
    width: 13px;
    height: 13px;
}

.close {
    color: #D8D8D8;
    font-size: 17px;
    margin: auto;
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 27px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s;
}

.close:hover {
    color: red;
}

.floatingPlaceholder {
    position: absolute;
    pointer-events: none;
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #959595;
    line-height: 1;
    left: 5px;
    z-index: 1;
    font-size: 12px;
}

.floatingPlaceholder[data-size='big'] {
    transform: translate(0, 14px) scale(1);
}

.floatingPlaceholder[data-size='small'] {
    transform: translate(0, 9px) scale(1);
}

.floatingPlaceholder[data-size='medium'] {
    transform: translate(0, 12px) scale(1);
}

.collapsedFloatingPlaceholder {
    transform: translate(0, -5px) !important;
    background-color: white !important;
    white-space: nowrap;
}

.collapsedFloatingPlaceholder[data-size='small'] {
    font-size: 11px !important;
}