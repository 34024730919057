.container {
    display: flex;
    min-height: 25px;
    gap: 5px;
    margin-top: 5px;
}

.container > div:first-child {
    display: flex;
    gap: 5px;
    align-items: center;
}

.container > div:first-child svg {
    padding-top: 2px;
}

.container > div:last-child {
    flex: 1;
}

.tags {
    padding: 4px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3em;
    overflow: auto;
}
