.container {
    margin: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkboxesContainer {
    display: flex;
    justify-content: space-between;
}