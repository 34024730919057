.menu {
    z-index: 1007;
    width: 250px;
    background-color: white;
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 3px;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    transition: all 0.4s;
}

.option {
    height: 40px;
    font-size: 14px;
    color: #111C35;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    transition: all 0.4s;
    padding: 0 10px;
}

.option:hover {
    background-color: #EFEFEF;
}

.option.selected {
    background-color: #EFEFEF;
}
