.container {
    display: grid;
    grid-template-columns: 70% 30%;
}

.grid {
    display: grid;
    grid-template-rows: calc(100% - 50px) 50px;
    height: 100%;
    width: 100%;
}


.footer {
    border-top: 0.01rem solid #e0e0e0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.pager > svg {
    color: #c2c2c2;
    font-size: 12px;
}

.pager > div {
    display: flex;
    gap: 15px;
}

.content {
    padding: 5px;
}

.content > div {
    border: 0.01rem solid darkgrey;
    border-radius: 2px;
    height: 100px;
    margin-bottom: 10px;
    padding: 5px;
}

.skeletonRow {
    flex: 1;
}

.group {
    display: flex;
    gap: 10px;
}