.container {
    --alert-color: var(--red-color);
    --warning-color: rgb(255, 200, 0);
    --information-color: rgb(0, 111, 230);
    --border-color: rgb(210, 210, 210);

    border-top: 8px solid;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.container[data-type="alert"] {
    border-color: var(--alert-color);
}

.container[data-type="warning"] {
    border-color: var(--warning-color);
}

.container[data-type="information"] {
    border-color: var(--information-color);
}

.buttonsRow {
    align-items: center;
    border-top: 1px solid var(--border-color);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    padding: 10px;
    gap: 10px;
}

.contentRow {
    padding: 24px 0;
    display: flex;
}

.contentRow > div:first-child {
    display: flex;
    align-items: center;
}

.contentRow h1 {
    color: rgba(50, 50, 50);
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 8px;
}

.contentRow p {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    line-height: 20px;
    color: rgba(50, 50, 50);
    margin: 0 5px;
    font-size: 14px;
}

.icon {
    margin: 0 24px;
    font-size: 50px;
}

.container[data-type="alert"] .icon {
    color: var(--alert-color);
}

.container[data-type="warning"] .icon {
    color: var(--warning-color);
}

.container[data-type="information"] .icon {
    color: var(--information-color);
}


