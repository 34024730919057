.container {
    border: 1px solid var(--main-bright-gray-color);
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 2px;
}

.container:hover {
    border-color: rgba(0, 29, 100, .2);
    background-color: white !important;
}

.card {
    height: 100%;
    margin: 0;
}

.card:hover {
    background-color: white !important;
}

.copy {
    position: absolute;
    right: 10px;
    color: var(--main-orange-color);
    font-size: 11px;
    font-weight: bold;
    margin: auto 0;
}

.copy:hover {
    text-decoration: underline;
}