.panel[data-display='default'] {
    width: 360px;
    max-width: 360px;
    height: 100%;
    background-color: white;
    border-right: 1px solid #DBDBDB;
}

.panel[data-display='absolute'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1005;
}

.filterBtnContainer {
    display: flex;
    justify-content: end;
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
}