.overlay {
    position: fixed;

    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    overflow: hidden;
    z-index: 1007;

    display: flex;
    align-items: center;
    justify-content: center;
}

.body {
    z-index: 1006;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    border-radius: 3px;
    max-width: 98vw;
    max-height: 98vh;
}
