.btnContainer {
    margin: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}


.link {
    background-color: inherit;
    border: none;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    color: #000000;
    margin-right: 5px;
}

.link:hover {
    text-decoration: underline;
}

.dialog {
    height: 100%;
    overflow: auto;
}
