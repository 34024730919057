.grid {
    width: 100%;
    height: 100%;
    border-color: var(--grid-border-color);
    overflow: hidden;

    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.footer {
    height: 47px;
    min-height: 47px;
    background-color: #fafafa;
    padding-inline: 8px;
    padding-block: 8px;
    color: #424242;
    border-width: 1px 0 0;
    border-color: inherit;
    border-top: 1px solid var(--grid-border-color);
    display: flex;
    align-items: center;
    justify-content: end;
}

.header {
    display: flex;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid var(--grid-border-color);
    border-top: 1px solid var(--grid-border-color);
}

.header > div[data-type='one-row'] {
    display: flex;
    align-items: start;
    justify-content: center;
    border-right: 1px solid var(--grid-border-color);
    overflow: hidden;
}

.header > div[data-type='one-row'] > div {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.header > div[data-type='two-rows'] {
    display: grid;
    grid-template-rows: 20px 40px;
    border-right: 1px solid var(--grid-border-color);
    overflow: hidden;
}

.header > div[data-type='two-rows'] > div {
    width: 100%;
}

.header > div[data-type='two-rows'] > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--grid-border-color);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.header > div:last-child {
    border-right: none;
}

.header > div span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
}

@media screen and (max-width: 1024px) {
    .headerContainer {
        display: none;
    }
}

.loader {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 3;
    background-color: rgba(234, 239, 243, 0.6);
    cursor: progress;
}

.loader > div {
    -webkit-transform: translateX(-50%) translateY(-50%);
    left: 50%;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1000;

    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 2px;
}

.notifyRoleRow {
    display: flex;
}

.notifyRoleRow > div {
    width: calc(100% / 6);
}

.notifyIfSenderRow {
    display: flex;
}

.notifyIfSenderRow > div {
    width: calc(100% / 3);
}

.notifyRoleRow > div, .notifyIfSenderRow > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.notifyRoleRow > div > div:last-child, .notifyIfSenderRow > div > div:last-child {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
}

.collapse {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
}

.collapse::before {
    left: 5px !important;
    width: calc(100% - 10px) !important;
}

.collapse::after {
    left: 5px !important;
    width: 20% !important;
}

.collapse > div:nth-child(2) {
    font-weight: bold;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 5px;
    justify-content: start !important;
}

.collapse > div {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}