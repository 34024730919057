.button {
    background-color: white;
    border: none;
    color: #7FB98B;
    cursor: pointer;
    font-size: 12px;

}

.button:hover {
    text-decoration: underline;
}

.btnContainer {
    text-align: right;
}

.noHistory {
    background-color: #eaeff3;
    font-size: 12px;
    color: var(--red-color);
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
}

.item {
    background-color: #eaeff3;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 2px;
    font-size: 12px;
}

.item label {
    color: #969696;
}

.row {
    display: flex;
    justify-content: space-between;
}

.dRow {
    margin: 3px 0;
    display: flex;
    gap: 5px;
}
