.container {
    display: flex;
    gap: 10px;
}

.container > div {
    width: calc(100% / 3);
}

.header {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    padding-bottom: 3px;
    margin-bottom: 10px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewLabel {
    white-space: pre-line;
    font-size: 14px;
    color: #4E4E4E;
}

@media screen and (max-width: 760px) {
    .container {
        flex-direction: column;
        overflow: auto;
        height: 500px;
    }

    .container > div {
        width: 100%;
    }
}