.title {
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid var(--main-bright-gray-color);
    margin-bottom: 2px;
}

.text {
    font-size: 10px;
    color: #444;
}