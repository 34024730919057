.logo {
    margin: 15px 0;
    text-align: center;
}

.logo > img {
    width: 30%;
}

.languageSwitcherContainer {
    padding: 5px;
    display: flex;
    justify-content: end;
}

.label {
    text-align: center;
    font-weight: 400;
    color: #4a4a4a;
    margin: 10px 0 0 0;
    font-size: 19px;
}

.label > div {
    font-size: 12px;
    font-weight: bold;
}


.separator {
    margin: 10px 0;
    border: 0.5px solid #D8D8D8;
}

.linkBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.linkBtns > button {
    color: #999;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    border: none;
}

.linkBtns > button:hover {
    color: var(--main-orange-color);
}

.linkBtns > button:disabled {
    cursor: not-allowed;
}

.btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btns > div {
    position: relative;
}

.tooltip {
    position: absolute;
    top: 0;
    right: -15px;
    color: #cccccc;
    font-size: 12px;
}

.tooltip:hover {
    color: black;
}
