.container {
    flex-direction: column;
}

.hasTitle {
    gap: 10px;
}

.container b {
    color: #4a4a4a;
    font-size: 12px;
}