:root {
    --bg-color: #dde1e7;
    --main-orange-color: #E96024;
    --red-color: #EE2012;
    --main-blue-color: rgba(0, 29, 100);
    --main-bright-gray-color: #F7F7F7;
    --darkred-color: darkred;
    --light-blue-color: rgb(63, 140, 229);

    --grid-border-color: #dbdbdb;
    --sidebare-color: #111C35;
    --neumorph-card-bs-fi: rgba(255, 255, 255, .7);
    --neumorph-card-bs-se: rgba(255, 255, 255, .5);
    --neumorph-card-bs-th: rgba(255, 255, 255, .075);
    --neumorph-card-bs-fo: rgba(0, 0, 0, .15);
}

.dark {
    --bg-color: #1d1f27;

    --neumorph-card-bs-fi: rgba(255, 255, 255, 0.05);
    --neumorph-card-bs-se: rgba(255, 255, 255, 0.05);
    --neumorph-card-bs-th: rgba(0, 0, 0, 0.5);
    --neumorph-card-bs-fo: rgba(0, 0, 0, 0.5);
}


.user-link {
    color: #1b1b1b;
}
.user-link:hover {
    color: #7FB98B;
    text-decoration: underline;
    cursor: pointer;
}

#offer-has-access-filter-applied, #quote-has-access-filter-applied, #shipment-has-access-filter-applied,
#invoice-has-access-filter-applied, #disposition-has-access-filter-applied {
    cursor: pointer;
}

.error-label {
    font-size: 11px;
    color: var(--red-color);
    font-weight: bold;
    margin: 2px 0;
}

.input-error-label {
    font-size: 11px;
    color: var(--red-color);
    font-weight: bold;
    margin: 2px 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.input-error-label svg {
    font-size: 13px;
}

.prevent-selection {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.prevent-image-selection {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.pd-5 {
    padding: 5px;
}

.pd-10 {
    padding: 10px;
}

.mg-10 {
    margin: 10px;
}

.mgb-10 {
    margin-bottom: 10px;
}

.mgt-10 {
    margin-top: 10px;
}

.mgt-20 {
    margin-top: 20px;
}

.mgb-5 {
    margin-bottom: 5px;
}

.mgt-5 {
    margin-top: 5px;
}

.mgb-20 {
    margin-bottom: 20px;
}

.toast-body-class {
    font-size: 12px;
}

.text-no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.h100 {
    height: 100%;
}

.w100 {
    width: 100%;
}

.o-auto {
    overflow: auto;
}

.hidden {
    overflow: hidden;
}

.block {
    display: block;
}

.relative {
    position: relative;
}

.transition-all-500 {
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.action-loader-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-bright-gray-color);
}

.action-loader-container > div {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 2px;
    position: relative;
}

.form-layout-default-tab-container {
    width: 100%;
    overflow: auto;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    white-space: nowrap;
    border-bottom: 1px solid #dbdbdb
}

.details-box {
    padding: 10px;
    margin: 0 10px;
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 5px;
}

.details-box .col-sm-3, .col-sm-3 {
    width: calc(100% / 3);
    display: inline-block;
    vertical-align: top;
}

.details-box .col-sm-2, .col-sm-2 {
    width: calc(100% / 2);
    display: inline-block;
    vertical-align: top;
}

.details-box .col-sm, .col-sm {
    width: 100%;
    vertical-align: top;
    margin-bottom: 10px;
}

.details-box .details-box-col-item label {
    font-size: 12px;
    display: block;
    color: #969696;
    margin-bottom: 0;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.details-box .details-box-col-item {
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 145px calc(100% - 145px);
}

.side .details-box .details-box-col-item {
    display: block;
}

.details-box-field-icon {
    font-size: 13px;
    color: var(--main-orange-color);
    cursor: pointer;
}

.details-box-field-edit-text {
    font-size: 12px;
    color: var(--main-orange-color);
    cursor: pointer;
    font-weight: bold;
}

.details-box-field-value {
    font-size: 14px;
    color: #4a4a4a;
    min-height: 14px;
}

.etaStatusLate {
    display: inline;
    color: #c9302c;
    margin-right: 5px;
}

.etaStatusOnTime {
    display: inline;
    color: #7FB98B;
    margin-right: 5px;
}

.etaStatusEarly {
    display: inline;
    color: #FFCF00;
    margin-right: 5px;
}

.noEta {
    display: none !important;
}

.details {
    color: #4a4a4a;

    font-size: 14px;
}

.details .view-column {
    width: calc(100% / 3);
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
}

.details .view-column4 {
    width: 23%;
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
}

.view-row {
    margin-top: 2px;
}

.view-label {
    font-size: 14px;
    display: block;
    color: #4E4E4E;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    padding-bottom: 5px;
}

.required:after {
    content: '*';
    margin-left: 2px;
    font-size: 12px;
    color: var(--red-color);
}

.view-value {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    margin-top: 2px;
    font-size: 12px;
    min-height: 14px;
}


.details .two-columns {
    width: 49%;
    display: inline-block;
    vertical-align: top;
}

@media (max-width: 1024px) {
    .details .two-columns {
        width: 100%;
        display: inline-block;
        vertical-align: top;
    }

    .details .view-column, .details .view-column4 {
        width: 49%;
        display: inline-block;
        vertical-align: top;
    }

    .details .view-column, .details .view-column4 {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
    }
}

.side .view-column, .side .view-column4 {
    width: 100% !important;
}

.warn-pink {
    color: white;
    background-color: var(--red-color);
    padding: 2px 10px !important;
    border-radius: 24px !important;
}

.warn-red {
    color: white;
    background-color: var(--red-color);
    padding: 2px;
}

.dialog-default-buttons-container {
    padding: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.dialog-default-content-container {
    max-height: calc(100vh - 150px);
    overflow: auto;
}

.horizontal-radio-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.horizontal-radio-buttons-container.end {
    justify-content: end;
}

/* Map */

.pac-container {
    z-index: 1008 !important;
}

.pac-target-input {
    background-color: #fff;
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 50%;

    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.pac-target-input:focus {
    border-color: #4d90fe;
}

.pac-outer-container {
    width: 100%;
}

.pac-button-outer-container {
    margin: 10px 10px 0 0;
}

/* Location map info window */
.location-map .gm-style .gm-style-iw-c {
    border-radius: 2px;
}

.location-map .gm-style-iw .gm-ui-hover-effect > span {
    -webkit-mask-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E);
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}

.badge.blue {
    background-color: var(--main-blue-color);
}

.default-form-collapse {
    margin-bottom: 10px;
}

.default-form-collapse-header {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
}

/*.default-form-collapse-header::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #f1f1f1;
}

.default-form-collapse-header::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
    height: 2px;
    background: var(--main-orange-color);
}*/

.default-form-collapse-title {
    font-size: 15px;
    color: #1b1b1b;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.default-form-collapse-icon {
    color: lightgray;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}


/* Bet list */

.bet-list-container {
    margin-top: 2px;
    padding: 5px;
}

.bet-card {
    border: 1px solid rgba(240, 83, 4, .4);
    margin-bottom: 5px;
    border-radius: 2px;
    color: #1b1b1b;
    font-size: 11px;
}

.cancelled-bet {
    color: #969696 !important;
    border: 1px solid lightgrey;
    border-left: 3px solid var(--red-color);
}

.bet-card:not(.cancelled-bet):hover {
    border-color: var(--main-orange-color);
}

.bet-rejected-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom-right-radius: 1px;
    color: var(--red-color);
}

.bet-rejected-reason-container {
    height: 18px;
    line-height: 18px;
    border-bottom: 1px solid lightgrey;
    margin-left: 18px;
    color: var(--red-color);
    font-weight: bold;
    font-size: 11px;
    padding-left: 3px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bet-rejected-notes-container {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 11px;
    font-weight: 500;
    color: var(--red-color);
    padding: 2px 5px;
}

.bet-field {
    padding: 2px 5px;
}

.bet-prop-field {
    font-weight: bold;
    font-size: 20px;
}

.bet-active {
    color: var(--main-orange-color);
}

.flex-two-columns, .flex-three-columns, .flex {
    display: flex;
}

.flex-two-columns > div {
    width: 50%;
}

.flex-three-columns > div {
    width: calc(100% / 3);
}

.flex-gap-5 {
    gap: 5px
}

.flex-gap-10 {
    gap: 10px
}

.flex-gap-15 {
    gap: 15px;
}

.flex-gap-20 {
    gap: 20px;
}

.flex-center-vertically {
    align-items: center;
}

.scrollbar-to-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}

/* Default close button */

.default-form-close-btn {
    transition: all 0.5s;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
    border: 1px solid #efefef;
}

.default-form-close-btn:not(:disabled):hover {
    border-color: #DBDBDB;
}

.default-form-close-btn svg {
    font-size: 10px;
    color: #292929;
    transition: all 0.2s;
}

.default-form-close-btn:disabled {
    cursor: not-allowed;
}


