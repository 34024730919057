.container {
    background-color: white;
    border-radius: 2px;
    display: grid;
    grid-template-rows: calc(100% - 50px) 50px;
}

.label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.error {
    text-align: right;
    flex: 1;
    margin: 0;
}

.separator {
    height: 1px;
    background-color: var(--main-bright-gray-color);
    margin: 10px 0;
}

.btns {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px
}
