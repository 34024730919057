.container {

}

.button {
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 1px;
}

.new {
    background-color: var(--main-orange-color);
}

.old {
    /*background-color: #B0B0B0;*/
}
