.container {
    display: grid;
    grid-template-columns: calc(50% - 5px) 10px calc(50% - 5px);
    margin-bottom: 20px;
}

.container > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container > div:nth-child(2) svg {
    font-size: 40px;
    color: #dadada;
}

.point {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
}

.point > div {
    padding: 15px;
}

.point > header {
    border-bottom: 1px solid #dbdbdb;
    color: #1b1b1b;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
}

.point > header > span {
    font-size: 10px;
    border-radius: 2px;
    padding: 3px 5px;
}

.row {
    margin-bottom: 5px;
}

.route {
    margin-bottom: 15px;
    text-transform: uppercase;
    color: #1b1b1b;
    font-size: 14px;
    font-weight: bold;
}


.group {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.right {
    float: right;
}

.direction {
    font-size: 12px;
    color: var(--main-orange-color);
    font-weight: bold;
}

.direction svg {
    margin-right: 5px;
}

.icon[data-custom-value-attr='[P]'] {
    color: #e96024;
}

.icon[data-custom-value-attr='[D]'] {
    color: #7FB98B;
}

@media screen and (max-width: 600px) {
    .container {
        display: grid;
        grid-template-rows: calc(50% - 35px) 70px calc(50% - 35px);
        grid-template-columns: none;
        margin-bottom: 20px;
    }
}

.warn {
    font-weight: bold;
    color: white;
    background-color: var(--red-color);
    padding: 2px 10px !important;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 24px;
    text-align: center;
}
