.container {

    border-radius: 2px;
    min-height: 670px;
    display: flex;
    flex-direction: column;
}

.columns {
    display: grid;
    grid-template-columns: 450px calc(100% - 450px);
}

.columns > div:last-child {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.route {
    display: flex;
    align-items: center;
    gap: 10px;
}

.route > div {
    display: flex;
    gap: 5px;
    align-items: center;
}

.route img {
    width: 23px;
}

.routeDash {
    margin-top: -3px;
}

.radioRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.radioRow label {
    color: #4a4a4a;
    font-size: 13px;
}

.btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tooltip {
    color: #cccccc;
    font-size: 12px;
    margin: 0 0 4px 4px;
}

.tooltip:hover {
    color: black;
}

.changeTypeRadioContainer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.datesContainer {
    display: flex;
    align-items: center;
    gap: 5px
}

.datesContainer > div {
    width: 25%;
}