.tooltip {
    color: #cccccc;
    font-size: 12px;
    margin: 0 0 0 4px;
}

.tooltip:hover {
    color: black;
}

.spList {


    overflow: hidden;
    padding: 5px;
}

.spList > div:not(:last-child) {
    margin-bottom: 5px;
}

.container {

    padding: 0 5px;
}

.leg {
    font-weight: bold;
    color: #4a4a4a;
    font-size: 12px;
}

.icon {
    margin-right: 5px;
    font-size: 13px;
}

.icon[data-sp-type='[P]'] {
    color: var(--main-orange-color);
}

.icon[data-sp-type='[D]'] {
    color: #7FB98B;
}

.spLabel {
    display: flex;
    margin-bottom: 2px;
    align-items: center;
}

.address {
    color: #4a4a4a;
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px;
}

.dateTimePickerContainer {
    display: flex;
    gap: 5px;
}