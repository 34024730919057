.header[data-visibility='hidden'], .grid[data-visibility='hidden'] {
    visibility: hidden;
    max-height: 0;
}

.content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.container[data-markup='f'] {
    display: flex;
    gap: 1px;
}

.container[data-markup='f'] .content {
    width: calc(100% - 360px);
}

.filterHeader {
    display: grid;
    grid-template-columns: 40px calc(100% - 40px);
}

.filterHeader > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterBtn[data-open='false'] svg {
    font-size: 17px;
    color: #292929;
}