.logoContainer {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--main-orange-color);
}

.logoContainer img {
    width: 40%;
}

.headerText {
    text-align: center;
    padding: 15px 0;
    font-weight: bold;
    color: #515050;
    font-size: 16px;
}

.text {
    font-size: 12px;
    color: #515050;
    font-weight: bold;
    margin: 20px 0;
}

.separator {
    margin: 10px 0;
    border: 0.01rem solid #D8D8D8;
}

.btn {
    border: 1px solid var(--red-color);
    color: var(--red-color);
    background-color: white;
    border-radius: 2px;
    padding: 1px 10px;
    font-weight: bold;
    cursor: pointer;
}

.paperPlane {
    text-align: center;
    font-size: 60px;
    color: #017195;
}

.resendBtn {
    padding: 0 5px;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background-color: inherit;
    font-weight: bold;
    font-size: 12px;
    color: #515050;
}

.resendBtn:disabled {
    color: var(--red-color);
    cursor: not-allowed;
    text-decoration: line-through;
}
