

.button {
    background-color: #7FB98B;
    color: white;
    border-radius: 2px;
    border: none;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    height: 27px;
    margin-right: 15px;
}

.button svg {
    font-size: 14px;
}
.box {
    padding: 5px 15px;
}
.box[data-view='mobile'] .col {
    width: calc(100% / 2);
}

.col {
    display: inline-block;
    width: calc(100% / 3);
    vertical-align: top;
}

.col label {
    font-size: 14px;
    display: block;
    color: #1b1b1b;
    margin-bottom: 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.col > div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.col > div[data-display='block'] {
    flex-direction: column;
    align-items: start;
}

.col > div > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 14px;
    word-break: break-word;
    padding-right: 5px;
    font-size: 14px;
    color: #1b1b1b;
}

.copy {
    color: #7FB98B;
    cursor: pointer;
    font-size: 15px;
}

.email {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.email a {
    color: #1B3274;
    font-size: 14px;
    text-decoration: none;
}
