.container {
    min-height: 100px;
    position: relative;
    border: 0.01rem solid #D8D8D8;
    border-radius: 2px;
    max-height: 160px;
    overflow: auto;
}

.title {
    color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.separator {
    margin: 10px 0;
    border: 0.01rem solid #D8D8D8;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row {
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #878787;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 25px;
}

.row > div:first-child {
    width: 25px;
    display: flex;
    justify-content: center;
    color: var(--main-orange-color);
    font-size: 17px;
}

.row > div:nth-child(2) {
    width: calc(100% - 25px);
    display: flex;
    gap: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
}

.row > div:nth-child(2) > span:first-child {
    font-size: 13px;
    font-weight: bold;
}

.row > div:nth-child(2) > span:nth-child(2) {
    font-size: 12px;
}

.row:hover, .active {
    outline: 1px solid var(--main-orange-color);
}
