.container {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cardText {
    font-size: 55px;
    font-weight: bold;
    color: var(--main-orange-color);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstRowText {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: var(--main-orange-color);
    margin: 20px 0 10px 0;
}

.secondRowText {
    text-align: center;
    font-weight: bold;
    color: var(--darkred-color);
}

.secondRowText span {
    margin: 0 10px;
    font-size: 20px;
    cursor: pointer;
    color: var(--main-orange-color);
    text-decoration: underline;
}

.logo {
    width: 350px;
    position: absolute;
    top: 10px;
    left: 10px;
    pointer-events: none;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}