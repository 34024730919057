.item {
    width: 20%;
    vertical-align: top;
    position: relative;
    color: #969696;
}

.passed {
    color: #4a4a4a;
}

.line[data-passed='p'] {
    border-color: #7FB98B !important;
}

.line[data-type='Solid'] {
    width: 100%;
    border-top: 6px solid #b6b6b6
}

.line[data-type='Dashed'] {
    width: 100%;
    border-top: 6px dashed #b6b6b6
}

.line[data-type='None'] {
    width: 100%;
    border: none;
    padding-top: 6px;
}

.last {
    border-right: 2px solid #969696;
}

.first {
    border-left: 2px solid #969696;
}

.indicator {
    width: 20px;
    position: absolute;
    top: -9px;
    left: 0;
}

.data {
    margin: 25px 5px 15px 10px;
}

.circle {
    width: 38px;
    height: 38px;
    border: 6px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    top: -16px;
    left: -1px;
    background-color: white;
}

.circle[data-passed='p'] {
    background-color: #7FB98B;
    border-color: #7FB98B;
}

.circle[data-passed='np'] {
    border-color: #b6b6b6;
}
