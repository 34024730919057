.grid {
    border: 0.01rem solid #e0e0e0;

    display: grid;
    grid-template-rows: 42px calc(100% - 92px) 50px;
}

.header {
    border-top: 0.01rem solid #e0e0e0;
    display: flex;
}

.header > div {
    width: 100%;
    position: relative;
}

.header > div:not(:last-child) {
    border-right: 0.01rem solid #e0e0e0;
}

.headerSkeleton {
    border-radius: 2px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.footer {
    border-bottom: 0.01rem solid #e0e0e0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.pager > svg {
    color: #c2c2c2;
    font-size: 12px;
}

.pager > div {
    display: flex;
    gap: 15px;
}