.sidebar {
    display: grid;
    grid-template-rows: 50px auto 1fr auto auto;
    height: 100%;
    background-color: #111C35;
    overflow: hidden;

    transition-duration: 200ms;
    transition-delay: 1ms;
    transition: width 1s, max-width 0.5s;
    transition-timing-function: ease;
}

.sidebar[data-expanded='expanded'] {
    width: 280px;
    max-width: 280px;
}

.sidebar[data-expanded='condensed'] {
    width: 60px;
    max-width: 60px;

    position: fixed;
    z-index: 1005;
    left: 0;
    top: 0;
}

.sidebar[data-hover-expanded='true'] {
    width: 280px;
    max-width: 280px;
}

.fake {
    width: 60px;
    max-width: 60px;
}