.container {
    border-radius: 10px;
    background-color: #f7f7f7;
    padding: 5px 0;
    width: 100%;
}

.row {
    display: grid;
    grid-template-columns: 35px calc(100% - 35px);
}

.row > div:first-child {
    display: flex;
    justify-content: center;
    padding-top: 3px;
}

.label {
    color: #1b1b1b;
    font-size: 14px;
    font-weight: bold;
}

.text {
    font-size: 14px;
    color: #1b1b1b;
    margin-top: 5px;
}
