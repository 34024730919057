.container {
    margin: 10px 0 15px 0;
    transition: all 0.4s;
    display: flex;
    align-items: center;
}

.container[data-expanded='expanded'] {
    padding: 0 20px;
    gap: 10px;
}

.container[data-expanded='condensed'] {
    justify-content: center;
}

.menu {
    cursor: pointer;
    color: white;
}

.name {
    display: grid;
    grid-template-columns: calc(100% - 10px) 10px;
    flex: 1;
    cursor: pointer;
}

.name > div:last-child {
    display: flex;
    align-items: center;
    justify-content: end;
}

.text {
    color: #F6F6F6;
    font-size: 14px;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0;
    visibility: hidden;
    animation: appear 1s ease forwards;
    animation-delay: 0.1s;
}

@keyframes appear {
    20% {
        opacity: 0.20;
        visibility: visible;
        max-width: fit-content
    }
    30% {
        opacity: 0.30
    }
    40% {
        opacity: 0.40
    }
    50% {
        opacity: 0.50
    }
    60% {
        opacity: 0.60
    }
    70% {
        opacity: 0.70
    }
    80% {
        opacity: 0.80
    }
    90% {
        opacity: 0.90
    }
    100% {
        opacity: 1;
        visibility: visible;
        max-width: fit-content
    }
}
