.ring {
    margin-left: 7px;
}

.circle {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.text {
    font-size: xx-small;
}