.container {
    padding: 5px 20px 10px 20px;
}

.container[data-view='mobile'] .col {
    width: calc(100% / 2);
}

.col {
    display: inline-block;
    width: calc(100% / 3);
    vertical-align: top;
}

.col label {
    font-size: 14px;
    display: block;
    color: #1b1b1b;
    margin-bottom: 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.col > div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}


.col > div > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 14px;
    word-break: break-word;
    padding-right: 5px;
    font-size: 14px;
    color: #1b1b1b;

}


.tag > button {
    font-size: 10px;
    text-transform: uppercase;

}


