.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -320px;
    height: 100%;
    box-shadow: none;
    width: 320px;

    transition: all 0.4s ease-in;

    background-color: white;
    border-right: none;
    z-index: 1006;
}

.opened {
    box-shadow: 1px 0 5px -2px #878787;
    border-right: 1px solid #dbdbdb;
    left: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #dbdbdb;
}

.header > div:first-child {
    font-weight: bold;
    font-size: 14px;
}

.content {
    position: relative;
    flex: 1;
    min-width: 0;
    min-height: 0;
}

.btns {
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 320px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(1px) saturate(35%);
    backdrop-filter: blur(1px) saturate(35%);
    gap: 10px;
    padding: 0 5px;
}

.btns > button {
    text-transform: uppercase;
    font-size: 12px;
    height: 32px;
}