.popup {
    background-color: white;
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 2px;
    position: fixed;
    width: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.4s;
}

.open {
    visibility: visible;
    opacity: 1;
    z-index: 999;
    max-height: 250px;
}

.option {
    width: 100%;
    height: 100%;
    background: white;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    color: black;
    padding: 10px 5px;
    display: grid;
    grid-template-columns: calc(100% - 15px) 15px;
}

.option:hover {
    background-color: #efefef;
}

.disabled {
    background: #f9f9f9 !important;
    color: #aaa;
    cursor: not-allowed;
}

.tooltipContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip {
    color: #cccccc;
    font-size: 11px;
}

.tooltip:hover {
    color: #878787;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
