.container {
    margin-top: 5px;
    width: 100%;
}

.container > div {
    margin-bottom: 2px;
}

.twoCols {
    display: flex;
    gap: 2px;
}

.dirColumn {
    flex-direction: column;
}

.dirColumn > div {
    width: 100% !important;
}

.twoCols > div {
    width: 50%;
}

.dateError {
    outline: 0.05rem solid red !important;
}