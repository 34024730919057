.container {
    display: grid;
    grid-template-rows: 35px calc(100% - 35px);

    max-width: inherit;
    max-height: inherit;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
}



.close {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 21px;
    color: #000;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.close:hover {
    opacity: .8;
}

.title {
    font-size: 14px;
    color: #1b1b1b;
    font-weight: 700;
    text-transform: uppercase;
}

.content {
    height: 100%;
    overflow: auto;
}
