.panel {
    box-shadow: 10px 0 4px 11px #585858;
    height: 100%;
    width: 40%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 1001;
}

.close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
}
