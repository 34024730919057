.container {
    height: 55px;
}

.inputContainer {
    display: flex;
}

.inputContainer > span {
    display: flex;
    align-items: center;
    padding: 0 8px;
    border: 1px solid #acacac;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    color: var(--main-orange-color);
    border-right: none;
    background-color: white;
}

.inputContainer > span > svg {
    font-size: 14px;
}

.input {
    width: 100%;
    padding: 6px 5px;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #acacac;
    color: #333;

    border-radius: 0 2px 2px 0;
}

.input:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.input::placeholder {
    color: darkgray;
    font-size: 12px;
}

.input:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}
