.note {
    font-style: italic;
    font-size: 14px;
    color: #1b1b1b;
}

.content {
    display: flex;
    margin-top: 15px;
    gap: 5px;
}

.content > div:first-child {
    display: flex;
    gap: 5px;
    padding-top: 1px;
}

.content > div:first-child svg {
    padding-top: 2px;
}

.content > div:last-child {
    flex: 1;
    word-break: break-all;
}
