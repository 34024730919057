.tag {
    height: 28px;
    background-color: #efefef;
    border-radius: 20px;
    font-size: 12px;
    color: #111C35;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: all 0.2s;
}

.tag[data-type='tab'] {
    background-color: white;
    border: 1px solid #efefef;
    cursor: pointer;
}

.tag[data-count='true'] {
    gap: 3px;
}

.selected {
    border-color: #3F8CE5 !important;
    color: #3F8CE5;
}

.tag[data-icon='true'] > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tag[data-icon='true'] > div > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tag[data-icon='true'] svg {
    color: #1B3274;
    font-size: 9px;
    cursor: pointer;
}