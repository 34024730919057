
.dialog .k-checkbox-wrap {
    width: 25px;
    align-items: center;
    justify-content: center;
}

.dialog .k-checkbox-md {
    border: 1px solid #DBDBDB;
    border-radius: 2px;
    width: 15px;
    height: 15px;

    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

.dialog .k-checkbox:focus {
    box-shadow: none !important;
}

.dialog .k-checkbox-md:hover {
    border-color: #3F8CE5;
}

.dialog .k-checkbox:checked {
    border-color: #3F8CE5;
    background-color: #3F8CE5;
}

