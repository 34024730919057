.warn {
    font-weight: bold;
    color: white;
    background-color: var(--red-color);
    padding: 2px 10px;
    border-radius: 24px;
}

.info {
    background-color: #E5C51A;
    color: white;
    padding: 2px 10px;
    border-radius: 24px;
}
