.container {

    border-radius: 1px;
    padding: 5px;

}

.text {
    padding: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
}
