.dUploader {
    display: none;
}

.uploader {
    width: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in;
}

.drActive {
    border-color: #1B3274;
}

.disabled {

}

.selector {
    height: 50px;
    padding-block: 8px;
    padding-inline: 8px;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.files {
    border-top: 1px solid #D8D8D8;
    width: 100%;
}

.file {
    height: 50px;
    display: grid;
    grid-template-columns: 35px calc(100% - 70px) 35px;
    padding-inline: 5px;
    padding-block: 5px;
}

.file > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1B3274;
}

.file > div:first-child svg {
    font-size: 20px;
}

.file > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a4a4a;
}

.file > div:last-child > div {
    cursor: pointer;
    transition: all 0.2s ease-in;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.file > div:last-child > div:not(.clearDisabled):hover {
    background-color: #eaeaea;
}

.clearDisabled {
    cursor: not-allowed !important;
}

.file > div:last-child svg {
    font-size: 18px;
}

.file > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1px;
}

.fileName {
    color: #424242;
    font-size: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.size {
    font-size: 11px;
    color: #666666;
}

.error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    color: red;
}

.button {
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-family: inherit;
    background-color: #7FB98B;
    font-weight: bold;
}

.button:disabled {
    cursor: not-allowed;
    background-color: #ababab !important;
}

.button:not(:disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.dropHint {
    font-size: 12px;
    color: #696868;
}