.container {
    display: flex;
    flex-direction: column;
}

.logoContainer {
    margin: 20px 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer > div {
    width: 200px;
    height: 90%;
    background-color: var(--main-orange-color);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer img {
    width: 80%;
}