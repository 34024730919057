.help {
    width: 230px;
    height: 105px;
    background-color: var(--main-bright-gray-color);
    border-radius: 2px;
    border: 1px solid var(--main-bright-gray-color);

    transition: all 1000ms ease;
}

.help:hover {
    border-color: rgba(0, 29, 100, .4);
}

.iconContainer {
    margin: 10px 20px;
}

.iconContainer > svg {
    font-size: 20px;
    color: var(--main-blue-color);
}

.textContainer {
    margin: 10px 20px;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
}

.downloadIconContainer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 10px;
    font-size: 14px;
}

.downloadIconContainer svg {
    color: rgba(0, 29, 100, .6);
    cursor: pointer;
}

.downloadIconContainer svg:hover {
    color: var(--main-blue-color);
}