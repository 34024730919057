.buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    padding: 5px 0;
}

.sendRequest {
    margin: 0 5px;
    color: var(--main-blue-color);
    cursor: pointer;
}

.sendRequest:hover {
    text-decoration: underline;
}

.loaderContainer {
    position: absolute;
    z-index: 1001;
    background-color: rgba(234, 239, 243, .5);
}