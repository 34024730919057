.table {
    margin-top: 20px;
    width: 100%;
}

.passed {
    color: #1b1b1b;
}

.notPassed {
    color: #969696;
}

.tr {
    width: 25%;
    height: 75px;
}

.left {
    width: 25%;
    vertical-align: top;
}

.right {
    width: 75%;
    vertical-align: top;
}

.line {
    border: 2px none #969696;
    border-left-style: solid;
}

.icon {
    position: relative;
    z-index: 99;
    margin-left: -13px;
    float: left;
    width: 24px;
}

.rightText {
    padding-left: 25px;
    margin-top: -6px;
}

.etaDate {
    display: block;
}

.etaShift {
    padding: 2px;
    border-radius: 10%;
    color: white;
    width: max-content;
    display: block;
}


.row {
    height: 90px;
}

.col[data-float='left'] {
    width: 1%;
    vertical-align: top;
}

.col[data-float='center'] {
    width: 100px;
    text-align: center;
    vertical-align: top;
}

.col[data-float='right'] {
    width: calc(100% - 250px);
    vertical-align: top;
}

.date {
    color: #A7A7A7;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 2px;
}

.factDate, .address {
    font-size: 14px;

}

.eta {
    font-size: 14px;
    color: var(--red-color);
    font-weight: bold;
    white-space: nowrap;
}

.shift {
    padding: 3px;
    border-radius: 10%;
    color: white;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
}

.event {
    font-size: 14px;
    font-weight: bold;
}

.circle {
    width: 38px;
    height: 38px;
    border: 6px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.circle[data-passed='p'], .verticalLine[data-passed='p'] {
    background-color: #7FB98B;
    border-color: #7FB98B;
}

.circle[data-passed='np'] {
    border-color: #b6b6b6;
    background-color: white;
}

.verticalLine {
    position: absolute;
    height: 75px;
    width: 6px;
    background-color: #b6b6b6;
    left: 50%;
    transform: translateX(-50%);
    top: 35px;
}
