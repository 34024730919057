.container {
    height: 350px;
    width: 100%;
    position: relative;
    padding: 15px;
}

.container > div {
    border-radius: 10px;
}
