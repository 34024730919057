.container {
    display: flex;
    align-items: center;
    justify-content: start;
    color: white;
    font-weight: bold;
    gap: 3px;
}

.badge {
    padding: 2px 10px;
    border-radius: 24px;
}

.typeBadge {
    background-color: var(--main-blue-color);
}

.sourceBadge {
    background-color: var(--main-orange-color);
}

.crmBtn {
    border: none;
    background-color: inherit;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
