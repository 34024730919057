.collapse {
    margin-bottom: 10px;
}

.header {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
}

.header::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #f1f1f1;
}

.header::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
    height: 2px;
    background: var(--main-orange-color);
}

.title {
    font-size: 15px;
    color: #1b1b1b;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.icon {
    color: lightgray;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}
