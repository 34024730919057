.card {
    min-height: 40px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 5px calc(100% - 10px) 5px;
}

.card > div:nth-child(2) {
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 2px;
    background-color: #f8f8f8;
    display: flex;
}

.card > div:nth-child(2) > div {
    border-right: 1px solid var(--main-bright-gray-color);
}

.tooltip {
    position: absolute;
    top: 2px;
    right: 4px;
    color: #cccccc;
    font-size: 11px;
}

.tooltip:hover {
    color: black;
}

.name {
    position: relative;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
}