.icon {
    margin-right: 5px;
    font-size: 14px;
}

.icon[data-custom-value-attr='[P]'] {
    color: #e96024;
}

.icon[data-custom-value-attr='[D]'] {
    color: #7FB98B;
}

.edit {
    color: var(--main-orange-color);
    font-size: 15px;
    cursor: pointer;
    margin-left: 5px;
}
.edit:hover {
    color: var(--main-orange-color);
}
.sp {
    margin: 15px 0;
}

.spLabel {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.spLabel label {
    color: #1b1b1b;
    font-weight: bold;
    font-size: 14px;
}

.locationDot {
    font-size: 15px;
}

.needCheck {
    color: #e96024;
    cursor: pointer;
}

.noNeedCheck {
    color: #7FB98B;
    cursor: pointer;
}

.refineContainer {
    margin-left: 5px;
    white-space: nowrap;
}

.refineContainer a {
    text-decoration: underline;
    margin-left: 3px;

    font-size: 13px;
}

.address {
    font-size: 14px;

    color: #1b1b1b;
}
