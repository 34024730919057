.container {

    padding: 4px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3em;
    overflow: auto;
}
