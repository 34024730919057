.switcher {
    padding: 5px;
    display: flex;
    justify-content: end;
}

.logo {
    margin: 20px 0;
    text-align: center;
}

.logo > img {
    width: 150px;
}

.label {
    text-align: center;
    font-weight: 400;
    color: #4a4a4a;
    margin: 10px 0 0 0;
    font-size: 20px;
}

.separator {
    margin: 10px 0;
    border: 0.5px solid #D8D8D8;
}

.linkBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 10px;
}

.linkBtns > button {
    color: #999;
    font-size: 12px;

    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    border: none;
}

.linkBtns > button:hover {
    color: var(--main-orange-color);
}

.linkBtns > button:disabled {
    cursor: not-allowed;
}

.cooperationTermContainer {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.cooperationTermContainer > button {
    border: 1px solid rgba(0, 29, 100, .3);
    border-radius: 2px;
    width: 50%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    padding: 5px;
}

.cooperationTermContainer > button:hover {
    border-color: var(--main-blue-color)
}

.cooperationTermLabel {
    font-size: 11px;
    font-weight: bold;
    color: #878787;
}
