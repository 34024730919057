.toolbar {
    height: 50px;
    padding: 0 5px;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: space-between;
}

.toolbar > div {
    display: flex;
    gap: 5px;
    align-items: center;
}

.button {
    height: 28px;
    background-color: #7FB98B;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: all 0.2s;
    cursor: pointer;
    border: none;
}

.button > div > div:first-child {
    margin-bottom: 1px;
}


.button[color='red'] {
    background-color: var(--red-color);
}
