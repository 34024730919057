.select {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 32px;
    border-radius: 3px;
    font-size: 12px;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    color: black;
}

.select:hover {
    border-color: #e96024;
}

.input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    display: grid;
    grid-template-columns: calc(100% - 15px) 15px;
}

.input > div {
    display: flex;
    align-items: center;
}

.input > div:first-child {
    font-size: 12px;
    position: relative;
}

.input > div:last-child {
    justify-content: center;
}

.toggle {
    color: black;
    font-size: 5px;
}

.toggleOpened {
    transform: rotateX(180deg);
}

.tooltip {
    position: absolute;
    right: 2px;
    top: 2px;
    color: #cccccc;
    font-size: 14px;
}

.tooltip:hover {
    color: black;
}