.separator {
    border: 0.01rem solid var(--main-bright-gray-color);
}

.container[data-device='mobile'] {
    width: 100%;
    height: calc(100% - 55px);
}

.container[data-device='pc'] {
    width: 100%;
    height: calc(100% - 55px);
}