.buttonContainer {
    text-align: center;
}

.demoButtonContainer {
    margin-top: 10px;
}

.separator {
    margin: 10px 0;
    border: 0.5px solid #D8D8D8;
}

.linkButton {
    color: #999;
    font-size: 12px;

    text-decoration: underline;
    cursor: pointer;
    background-color: white;
    border: none;
}

.linkButton:hover {
    color: var(--main-orange-color);
}

.linkButton:disabled {
    cursor: not-allowed;
}

.relative {
    position: relative;
}

.clearButton{
    color: var(--red-color);
    font-size: 20px;
    position: absolute;
    top: 4px;
    right: 10px;
    cursor: pointer;
}
