:root {
    --chat-icon-online-color: #38b868;
    --chat-icon-offline-color: #bdbdbd;
    --chat-tufts-blue: #4283d7;
    --chat-panel-background-color: #f5f6f9;
    --chat-panel-border-color: #ebecf0;
    --chat-font-color-1: #2c262e;
}

.c-disabled {
    cursor: not-allowed;
}

.user-chat-icon {
    font-size: 18px;
    cursor: pointer;
}

.messages-bubble {
    width: 15px;
    height: 15px;
    min-width: 15px;
    background-color: var(--chat-tufts-blue);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
}

/* ChatPanel.tsx styles */
.chat-panel {
    position: absolute;
    top: 0;
    width: 950px;
    height: 600px;
    background-color: var(--chat-panel-background-color);
    border: 1px solid var(--chat-panel-border-color);
    z-index: 1005;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
}

.chat-panel-expanded {
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
}

/* ChatPanelLayout.tsx styles */
.chat-panel-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 20px calc(100% - 20px);
    overflow: hidden;
}


.chat-panel-layout > header {
    display: flex;
    padding: 0 10px;
    align-items: center;
}

.chat-panel-layout > header > section:first-child {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.chat-panel-layout > header > section:last-child {
    flex: 1;
    height: 100%;
    margin-left: 10px;
}

.chat-panel-layout main {
    padding: 5px 10px 10px 10px;
}

.chat-panel-layout-draggable-zone {
    cursor: move;
}

.chat-panel-action-button {
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-panel-action-button svg {
    font-size: 8px;
}

.chat-panel-action-n-button {
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

/* ChatSidebarButton.tsx styles */
.chat-online {
    color: var(--chat-icon-online-color);
}

.chat-offline {
    color: var(--chat-icon-offline-color);
}

.chat-sidebar-button-messages-bubble[data-type='condensed'] {
    position: absolute;
    right: 3px;
    top: 0;
}

.chat-sidebar-button-messages-bubble[data-type='expanded'] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
}

.chat-sidebar-button {
    height: 40px;
    width: 100%;

    transition: all 0.2s ease-in;
}

.chat-sidebar-button:hover {
    background-color: rgba(0, 0, 0, .1);
}

.chat-sidebar-button > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    transition: all 0.2s ease-in;
}

.chat-sidebar-button > div span {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.chat-sidebar-button[data-type='condensed'] > div {
    justify-content: center;
}

.chat-sidebar-button[data-type='expanded'] > div span {
    width: 40px;
}

/* ChatContext.tsx styles */

.chat-content {
    width: 100%;
    height: 100%;
}

.chat-content[data-view='default'] {
    display: grid;
    grid-template-columns: 320px calc(100% - 320px);
}

.container[data-view='mobile'] {
    display: block;
}


/* ChatContentChatListSection.tsx styles */
.chat-content-chat-list {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--chat-panel-border-color);

    display: grid;
    grid-template-rows: 70px calc(100% - 70px);
    overflow: hidden;
}

.chat-content-chat-list > header {
    padding: 10px 10px 0 10px;
}

.chat-content-chat-list > div {
    padding: 10px;
    overflow: auto;
}

.chat-content-chat-list > header > * {
    margin-bottom: 10px;
}

.chat-content-chat-list-title {
    font-size: 15px;
    color: var(--chat-font-color-1);
    font-weight: bold;
}

/* ChatSearchInput.tsx styles */


.chat-search-input-icon {
    position: absolute;
    left: 7px;
    font-size: 13px;
    margin: auto;
    color: #9a9eae;
    bottom: 0;
    top: 1px;
}

.chat-search-input {
    width: 100%;
    height: 30px;
    padding: 3px 4px 3px 25px;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid #ebecf0;
    color: #555;
    border-radius: 2px;
    background-color: #f3f4f8;
}

.chat-search-input:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.chat-search-input::placeholder {
    color: #b3b5c3;
    font-size: 10px;
    font-weight: bold;
}

.chat-search-input:disabled {
    cursor: not-allowed;
    background: rgba(243, 244, 248, .5);
    color: rgba(0, 0, 0, .8);
}

/* ChatAvatar.tsx styles */
.chat-avatar {
    border-radius: 50%;
    background-color: #f5f6f9;
    display: flex;
    align-items: center;
    color: #9a9eae;
    justify-content: center;
    border: 1px solid #ebecf0;
}

.chat-avatar[data-size='Big'] {
    width: 50px;
    height: 50px;
}

.chat-avatar[data-size='Medium'] {
    width: 40px;
    height: 40px;
}

.chat-avatar[data-size='Small'] {
    width: 30px;
    height: 30px;
}

.chat-avatar[data-size='Small'] svg {
    font-size: 14px;
}

.chat-avatar-marker {
    position: absolute;
    border-radius: 50%;
}

.chat-avatar-marker[data-size='Big'] {
    width: 12px;
    height: 12px;
}

.chat-avatar-marker[data-size='Small'] {
    width: 7px;
    height: 7px;
}

.chat-avatar-marker[data-size='Medium'] {
    width: 10px;
    height: 10px;
}

.chat-avatar-online {
    bottom: 0;
    right: 0;
    background-color: #27c281;
}

.chat-avatar-vacation {
    top: 0;
    right: 0;
    background-color: #ffd100;
}

/* ChatContentChatList.tsx styles */
.chat-content-chats-not-found {
    color: #b3b5c3;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}

/* ChatListCard.tsx styles */

.chat-list-card {
    width: 100%;
    height: 45px;
    border-radius: 2px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 46px calc(100% - 46px);
    cursor: pointer;
    padding: 0 5px 0 0;
}

.chat-list-card:hover {
    background-color: #f3f4f8;
}

.chat-list-card > div:first-child {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 1px;
}

.chat-list-card > div:last-child {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.chat-list-card > div:last-child > div {
    height: 18px;
}

.chat-list-card-selected {
    background-color: #f3f4f8;
}

.chat-list-card-message-row, .chat-list-card-name-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.chat-list-card-name-row > div:first-child {
    color: #434553;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-list-card-name-row > div:nth-child(2) {
    color: #a4a8b7;
    font-size: 11px;
    white-space: nowrap;
}

.chat-list-card-message-row > div:first-child {
    color: #a2a6b6;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* ChatContentDialogueSection.tsx styles */
.chat-content-dialogue-section-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9a9eae;
    font-size: 40px;
}

.chat-content-dialogue-section-info[data-type='mobile'] {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.chat-content-dialogue {
    width: 100%;
    height: 100%;
    padding-left: 5px;
}

.chat-content-dialogue[data-type='wi'] {
    display: grid;
    grid-template-columns: calc(100% - 230px) 230px;
    grid-gap: 5px;
}

.chat-content-dialogue[data-type='mobile'] {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 40px calc(100% - 40px);
    border: 1px solid #ebecf0;
    padding: 0;
}


.chat-dialogue-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 40px calc(100% - 90px) 50px;
    overflow: hidden;
}

.chat-dialogue-content[data-type='mobile'] {
    grid-template-rows: calc(100% - 50px) 50px;
}

.chat-dialogue-content-reply-to {
    grid-template-rows: 40px calc(100% - 140px) 100px !important;
}

.chat-dialogue-content-reply-to[data-type='mobile'] {
    grid-template-rows: calc(100% - 100px) 100px !important;
}

/* ChatDialogueHeader.tsx styles */

.chat-dialogue-header {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 25px calc(100% - 105px) 40px 40px;
    border-bottom: 1px solid #ebecf0;
}

.chat-dialogue-header > div:first-child, .chat-dialogue-header > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-dialogue-header > div:nth-child(2) {
    display: grid;
    grid-template-columns: 40px calc(100% - 40px);
}

.chat-dialogue-header > div:nth-child(2) > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-dialogue-header > div:nth-child(2) > div:last-child {
    display: flex;
    align-items: center;
    color: #434553;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-dialogue-header-circle {
    width: 20px;
    height: 20px;
    border: 1px solid #4283d7;
    color: #4283d7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.chat-dialogue-header-glass {
    color: #4283d7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.chat-dialogue-header-circle svg {
    font-size: 10px;
    margin-bottom: 1px;
}

.chat-dialogue-header-back {
    color: #4283d7;
    cursor: pointer;
    font-size: 20px;
}

/* ChatDialogueInformation.tsx styles */

.chat-dialogue-information-container {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ebecf0;
    display: grid;
    grid-template-rows: 100px calc(100% - 130px) 30px;
    overflow: hidden;
}

.chat-dialogue-information-header {
    background-color: #dbdde5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.chat-dialogue-information-header > div:last-child {
    color: #434553;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-dialogue-information-back {
    color: #4283d7;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
}


.chat-dialogue-information-row {
    display: flex;
    font-size: 12px;
    gap: 5px;
    margin: 10px 5px;
    align-items: center;
}

.chat-dialogue-information-row svg {
    font-size: 14px;
    color: #9a9eae;
}

.chat-dialogue-information-row > div:first-child {
    width: 15px;
}

.chat-dialogue-information-row > div:last-child {
    color: #434553;
    font-weight: bold;
    font-size: 11px;
}

.chat-dialogue-information-btns {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 5px;
}

.chat-dialogue-information-btn {
    border: 1px solid #9a9eae;
    border-radius: 2px;
    background-color: white;
    color: #9a9eae;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 6px;
}

.chat-dialogue-information-btn:hover {
    border-color: #4283d7;
}

/* ChatDialogueSendMessageButton.tsx styles */
.chat-dialogue-send-message-container[data-type='reply'] {
    display: flex;
    flex-direction: column;
}

.chat-dialogue-send-message-container[data-type='reply'] > div:first-child {
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #ebecf0;
    border-bottom: none;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 5px;
    align-items: end;
}

.chat-dialogue-send-message-container[data-type='reply'] > .chat-send-message-button-container {
    height: 50px !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.chat-send-message-button-container {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ebecf0;
    display: grid;
    grid-template-columns: calc(100% - 50px) 50px;
    overflow: hidden;
}

.chat-send-message-button-container > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-send-message-button {
    cursor: pointer;
    width: 40px;
    height: 30px;
    background-color: #7FB98B;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-send-message-button:disabled {
    cursor: not-allowed;
}

.chat-send-message-button-textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    padding: 5px;
    cursor: pointer;
    color: black;
    font-size: 14px;
}

.chat-send-message-button-textarea:hover, .chat-send-message-button-textarea:focus,
.chat-send-message-button-textarea:focus-visible, .chat-send-message-button-textarea:active,
.chat-send-message-button-textarea:focus-within {
    border: none;
    box-shadow: none;
    outline: none;
}

.chat-send-message-button-textarea:disabled {
    cursor: not-allowed;
}

.chat-send-message-button-textarea::placeholder {
    color: #b3b5c3;
    font-size: 12px;
}

/* ChatDialogueMessagesLoadingScreen.tsx styles */
.chat-dialogue-message-read {
    font-size: 10px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: white;
}

.chat-dialogue-message-not-read {
    font-size: 10px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: #ecedf3;
}

.chat-dialogue-messages-loading-screen-row {
    display: flex;
    margin: 10px;
    gap: 5px;
}

.chat-dialogue-messages-loading-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

/* ChatDialogueMessage.tsx styles */

.chat-dialogue-message, .reply-to-chat-dialogue-message {
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 40px) 40px;
    padding: 10px 0 10px 5px;
}

.reply-to-chat-dialogue-message > div:first-child {
    flex-direction: column;
    gap: 10px;
}

.reply-to-chat-dialogue-message > div:first-child[data-direction='to'] {
    align-items: start;
}

.reply-to-chat-dialogue-message > div:first-child[data-direction='from'] {
    align-items: end;
}

.chat-dialogue-reply-to-entity {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--main-orange-color);
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;
}

.chat-dialogue-message-reply-to-container {
    display: flex;
    gap: 5px;
}

.chat-dialogue-message-reply-to-container > div:last-child {
    background-color: #ece9e9;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: var(--main-orange-color);
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

.chat-dialogue-message-description {
    font-size: 12px;

}

.chat-dialogue-message-text-container[data-direction='from'] .chat-dialogue-message-reply-to-container {
    flex-direction: row-reverse;
}

.chat-dialogue-message-text-container[data-direction='to'] {
    display: flex;
    justify-content: start;
}

.chat-dialogue-message-text-container[data-direction='from'] {
    display: flex;
    justify-content: end;
}

.chat-dialogue-message-card {
    font-size: 12px;
    padding: 10px;
    border-radius: 3px;
    max-width: 70%;
    overflow-wrap: break-word;
}

.chat-dialogue-message-card[data-direction='to'] {
    background-color: #ecedf3;
    color: black;
}

.chat-dialogue-message-card[data-direction='from'] {
    background-color: #7D89BA;
    color: white;
}

.chat-dialogue-message-date-time-container {
    font-size: 11px;
    color: #a3a5b5;
    display: flex;
    align-items: start;
    justify-content: center;
}

/* ChatDialogueSearch.tsx styles */

.chat-dialogue-search-messages-section {
    position: absolute;
    top: 75px;
    width: 100%;
    left: 0;
    background-color: var(--chat-panel-background-color);
    z-index: 1001;
    border-bottom: 1px solid #ebecf0;
    border-left: 1px solid #ebecf0;
    border-right: 1px solid #ebecf0;
    box-shadow: rgba(50, 50, 93, 0.25) 0 7px 6px -6px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
    border-radius: 4px;
    max-height: 250px;
    overflow: auto;
}

.chat-dialogue-search-messages-message {
    display: grid;
    grid-template-columns: 40px calc(100% - 100px) 60px;
    width: 100%;
    height: 35px;
    cursor: pointer;
}

.chat-dialogue-search-messages-message > div:last-child {
    font-size: 10px;
    color: #a3a5b5;
    border-bottom: 1px solid #ebecf0;
}

.chat-dialogue-search-messages-message > div:nth-child(2) {
    border-bottom: 1px solid #ebecf0;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.chat-dialogue-search-messages-message-active {
    background-color: var(--main-bright-gray-color);
}

.chat-dialogue-search-messages-message > div:nth-child(2) > div:first-child {
    color: #434553;
    font-size: 10px;
    font-weight: bold;
}

.chat-dialogue-search-messages-message > div:nth-child(2) > div:last-child {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-dialogue-search-messages-message-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-dialogue-search-messages-section[data-type='mobile'] {
    background-color: white !important;
}

.chat-dialogue-search-section {
    position: absolute;
    top: 40px;
    width: 100%;
    height: 35px;
    left: 0;
    background-color: var(--chat-panel-background-color);
    z-index: 1000;
    border-bottom: 1px solid #ebecf0;
    border-left: 1px solid #ebecf0;
    border-right: 1px solid #ebecf0;
    box-shadow: rgba(50, 50, 93, 0.25) 0 7px 6px -6px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.chat-dialogue-search-section[data-type='mobile'] {
    background-color: white !important;
}

.chat-dialogue-search-section > div:first-child {
    display: grid;
    grid-template-columns: 50px calc(100% - 90px) 40px;
    height: 35px;
}

.chat-dialogue-search-messages-input {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.chat-dialogue-search-section-icon {
    color: #4283d7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 17px;
}
