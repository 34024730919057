.header {
    padding: 3px 5px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons {
    display: flex;
    gap: 5px;
}

.tabs {
    display: flex;
    gap: 10px;
}

.section {
    display: flex;
    align-items: center;
    gap: 10px;
}