.rulesLabel {
    font-size: 12px;
    font-weight: bold;
}

.rules {
    margin: 10px 0;
    width: 85%;
}

.rule {
    display: grid;
    grid-template-columns: 20px calc(100% - 20px);
    font-size: 13px;
    color: black;
}

.rule > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    font-size: 15px;
}

.green {
    color: #7FB98B;
}

.red {
    color: var(--red-color);
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.row {
    width: 85%;
}
