.tag {
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 10px;
    font-family: inherit;
    border: none;
    height: 20px;

    position: relative;
}

.tag[data-type='rounded'] {
    color: #292929;
    border-radius: 10px;
    height: 25px;
}

.tagMagnified {
    height: 25px;
    font-size: 13px;
    z-index: 1005;
}

.rectangle {
    height: 15px;
    width: 10px;
}

.close {
    margin-left: 0.4rem;
    font-size: 12px;
    color: white;
    cursor: pointer;
}

.closeRounded {
    margin-left: 0.4rem;
    font-size: 7px;
    color: var(--main-blue-color);
    cursor: pointer;
}

.closeMagnified {
    font-size: 14px;
}

.pen {
    margin-right: 0.4rem;
    font-size: 12px;
    color: white;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}
