.section {
    overflow: hidden;
}

.section[data-line='top'] {
    border-top: 1px solid #3A4661;
}

.section[data-line='bottom'] {
    border-bottom: 1px solid #3A4661;
}

.sidebar label {
    color: #677188;
    font-size: 12px;
    font-weight: bold;
}

.section[data-pd-bt='5'] {
    padding: 5px 0;
}

.section[data-pd-bt='10'] {
    padding: 10px 0;
}

.section[data-pd-all='5'] {
    padding: 10px;
}

.section[data-pd-all='10'] {
    padding: 10px;
}

.label {
    visibility: hidden;
    color: #677188;
    font-size: 12px;
    margin-left: 20px;
    line-height: 30px;
    transition: line-height 1s ease-in-out;
}

.visible {
    visibility: visible;
}
