.plate {
    color: white;
    padding: 4px;
    border-radius: 1px;
}

.yellow {
    background-color: #E5C51A;
}

.green {
    background-color: #7FB98B;
}

.gray {
    background-color: #959595;
}
