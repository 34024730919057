.menu {
    z-index: 1007;
    width: 250px;
    background-color: white;
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 3px;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    transition: all 0.4s;
    padding: 5px;
}

.tag {
    height: 28px;
    border-radius: 20px;
    font-size: 12px;
    color: #111C35;
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
    border: 1px solid white;
    margin: 3px 0;
}

.tag[data-type='tab'] {
    cursor: pointer;
}

.tag > div:first-child {
    width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag > div:last-child svg {
    color: #1B3274;
    font-size: 9px;
    cursor: pointer;
}

.tag[data-type='tag']:hover {
    background-color: #efefef;
    border-color: #efefef;
}

.tag[data-type='tab']:hover {
    border-color: #efefef;
}

.selected {
    border-color: #3F8CE5 !important;
    color: #3F8CE5;
}