.container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
}

.container[data-expanded='expanded'] > main {
    width: calc(100% - 280px);
}

.container[data-expanded='condensed'] > main {
    width: calc(100% - 60px);
}