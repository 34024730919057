.column {
    width: 100%;
}

.row {
    display: flex;
    gap: 5px;
}

.twoColumnRow {
    display: flex;
    gap: 2px;
}

.required {
    color: var(--main-orange-color);
    font-weight: bold;
    font-size: 11px;
}
