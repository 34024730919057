.svg-a-icon {
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

.svg-a-icon[viewBox] {
    width: auto;
}

.svg-a-icon-inline {
    overflow: visible;
    box-sizing: content-box;
}

.svg-a-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.svg-a-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.svg-a-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.svg-a-flip-vertical {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
}

.svg-a-flip-horizontal {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}