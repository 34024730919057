.otpGroup {
    display: flex;
    width: 100%;
    max-width: 250px;
    column-gap: 10px;
}

.otpInput {
    width: 100%;
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 2px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.otpInput:disabled {
    cursor: not-allowed;
}

.otpInput:focus-visible {
    outline: 2px solid var(--main-blue-color);
    outline-offset: -2px;
}
