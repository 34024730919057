.menu {
    position: absolute;
    width: 260px;
    z-index: 1004;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}



.ul {
    list-style: none;
    width: 100%;
    overflow: auto;
}

.ul li {
    cursor: pointer;
    color: #5c5c5c;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 5px;
    padding: 4px;

    &.wraped {
        white-space: wrap;
    }
}

.ul li > div {
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
}

.ul li:hover {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 1px;
}

.disabled {
    cursor: not-allowed !important;
    color: #8d8d8d !important;
}