.container {
    display: block;
    height: 35px;
}

.cfw {
    display: grid;
    grid-template-columns: 20px calc(100% - 20px);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 3px;
    padding: 1px;
}

.text {
    vertical-align: middle;
    cursor: pointer;
}

.addText {
    color: var(--main-orange-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 5px;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    font-size: 13px;
    color: var(--main-orange-color);
    cursor: pointer;
}
