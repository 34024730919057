.container {
    height: 350px;
    width: 100%;
    position: relative;
    padding: 15px;
}

.container > div {
    border-radius: 10px;
}

.pin {
    width: 14px;
    height: 14px;
    position: relative;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    z-index: 1000;
}

.pin[data-type="default"] {
    background: rgba(5, 124, 255, 1);
    border: 4px solid #FFF;
}

.pin[data-type="in"] {
    background: #8e44ad;
    border: 4px solid #FFF;
}
.pin[data-type="out"] {
    background: rgba(5, 124, 255, 1);
    border: 4px solid #FFF;
}

.pinPulsation {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    display: block;
    border-radius: 50%;
    opacity: 0;
    animation: pulsate 1s ease-out infinite;
}

.pinPulsation[data-type="default"] {
    background: rgba(5, 124, 255, 0.6);
}

.pinPulsation[data-type="in"] {
    background: #8e44ad;
}
.pinPulsation[data-type="out"] {
    background: rgba(5, 124, 255, 1);
}
.arrowRight::after {
    content: '';
    display: inline-block;
    margin-left: 1rem;
    width: 13px;
    height: 13px;
    border-top: 0.5em solid rgb(5, 124, 255);
    border-right: 0.5em solid rgb(5, 124, 255);
    transform: rotate(45deg);
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.arrowLeft::after {
    content: '';
    display: inline-block;
    margin-left: -1.6em;
    width: 13px;
    height: 13px;
    border-top: 0.5em solid rgb(5, 124, 255);
    border-right: 0.5em solid rgb(5, 124, 255);
    -moz-transform: rotate(-150deg);
    -webkit-transform: rotate(-150deg);
    transform: rotate(-150deg);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
