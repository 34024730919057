.user {
    color: #4e4e4e;
    font-size: 13px;
    margin-right: 5px;
}

.firstOrder {
    color: var(--light-blue-color);
    font-size: 17px;
    margin-right: 5px;
    vertical-align: middle;
}
