.none {
    display: none;
}

.hidden {
    visibility: hidden;;
}

.fullDetails {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1001;
    background-color: white;
}