.container > div:first-child {
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.container > div:first-child > div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #1b1b1b;
}

.tag > button {
    font-size: 10px;
    text-transform: uppercase;

}

.btns {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
}

.button {
    background-color: white;
    color: #7FB98B;
    border-radius: 2px;
    border: 1px solid #7FB98B;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    height: 27px;
    margin-right: 15px;
}

.button svg {
    font-size: 14px;
}

.link {
    margin-left: 5px;
}

.linkActive {
    cursor: pointer;
    color: var(--main-orange-color);
}

.box {
    padding: 5px 20px;
}
.box[data-view='mobile'] .col {
    width: calc(100% / 2);
}

.boxblocked {
    padding: 0px 20px;
}


.col {
    display: inline-block;
    width: calc(100% / 3);
    vertical-align: top;
}

.col label {
    font-size: 14px;
    display: block;
    color: #1b1b1b;
    margin-bottom: 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.col > div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.col > div[data-display='block'] {
    flex-direction: column;
    align-items: start;
}

.col > div > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 14px;
    word-break: break-word;
    padding-right: 5px;
    font-size: 14px;
    color: #1b1b1b;
}


/*




.card {
    border: 1px solid var(--main-bright-gray-color);
    border-radius: 2px;
    margin-bottom: 5px;
}

.header {
    min-height: 26px;
    display: flex;
    align-items: center;
    padding: 3px 5px;
    justify-content: space-between;
}

.header > div {
    display: flex;
    align-items: center;
    gap: 5px;
}



.icon {
    font-size: 12px;
    color: var(--main-blue-color);
}

.link {
    margin-left: 5px;
}

.linkActive {
    cursor: pointer;
    color: var(--main-orange-color);
}

.col {
    display: inline-block;
    width: calc(100% / 4);
    vertical-align: top;
}

.col label {
    font-size: 12px;
    display: block;
    color: #969696;
    margin-bottom: 0;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.col > div {
    margin-bottom: 5px;
}

.col > div > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 14px;
    word-break: break-word;
    padding-right: 5px;
}

.button {
    background-color: var(--main-orange-color);
    color: white;
    border: none;
    border-radius: 2px;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
}

.button svg {
    font-size: 12px;
}

@media screen and (max-width: 840px) {
    .button > div {
        display: none;
    }
}*/
