.user {
    margin-bottom: 10px;
    background-color: var(--main-bright-gray-color);
    border-radius: 2px;
    padding: 5px;
    min-height: 50px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: column;
}

.header > div:last-child {
    color: #434553;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.row {
    display: grid;
    grid-template-columns: 20px calc(100% - 20px);
    margin-bottom: 10px;
}

.row > svg {
    font-size: 14px;
    color: #9a9eae;
}

.row > span {
    color: #434553;
    font-weight: bold;
    font-size: 12px;
}

.user > div:last-child {
    margin-bottom: 0;
}

.copy {
    position: absolute;
    left: 10px;
    top: 10px;
    border: none;
    color: var(--main-orange-color);
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
}

.img {
    vertical-align: middle;
    width: 16px;
}

.roundTrip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    gap: 5px;
}

.roundTrip img {
    width: 18px;
}

.roundTrip span {
    color: #434553;
    font-weight: bold;
    font-size: 12px;
}