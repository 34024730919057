/* Override font-family */
.grid .k-grid {
    font-family: 'Lato', 'Open Sans', sans-serif !important;
}

/* Default grid checkbox */
.grid .k-checkbox {
    border-color: #dbdbdb;
    border-radius: 2px;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

.grid .k-checkbox:not(:checked):not(:disabled):hover {
    border-color: #3F8CE5;
}

.grid .k-checkbox:focus, .grid .k-checkbox.k-focus, .grid .k-checkbox:checked:focus {
    box-shadow: none;
}

.grid .k-checkbox:checked {
    border-color: #3F8CE5;
    background-color: #3F8CE5;
}

/* All grid border-color */
.grid .k-grid {
    border-color: var(--grid-border-color);
}

/* Header right/left/top border color */
.grid .k-grid .k-table-th {
    padding-block: 10px;
    border-color: var(--grid-border-color);
    border-right: 1px solid var(--grid-border-color);
    border-inline-start-width: 0px;
}

/* Header background color */
.grid .k-table-thead, .grid .k-table-th.k-sorted {
    background-color: white;
}

/* Header border bottom color */
.grid .k-grid-header {
    border-color: var(--grid-border-color);
}

/* Hide grouping drop area */
.grid .k-grouping-header {
    display: none !important;
}

/* Header height */
.grid .k-grid .k-grid-header .k-table {
    line-height: 25px;
}

/* If there is class .grid-header-center, header text will be centered */
.grid .grid-header-center .k-link {
    /*justify-content: center !important;*/
}

/* Sticky column header borders and background*/
.grid .k-grid-header .k-table-th.k-grid-header-sticky {
    background-color: white;
    border-right-color: #677188;
    border-left-color: #677188;
    border-right-width: 1px;
    border-left-width: 1px;
}

.grid .k-grid-header th.header-constantly-locked {
    border-right-color: #677188 !important;
    border-left-color: #677188 !important;
}

.grid .k-grid-header th.header-constantly-locked.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.k-grid .k-grid-header th.header-constantly-locked:first-child {
    border-inline-start-width: 1px !important;
    text-align: center;
}

/* Sticky column borders */
.grid .k-master-row .k-grid-content-sticky {
    border-inline-end-width: 1px !important;
    border-right-width: 1px;
    border-left-width: 1px;
    border-right-color: #677188 !important;
    border-left-color: #677188 !important;
}

.grid .k-master-row td.constantly-locked {
    border-right-color: #7f7f7f !important;
    border-left-color: #7f7f7f !important;
}

/* Sort icon color */
.grid .k-grid-header .k-sort-icon {
    color: var(--main-blue-color);
    font-size: 11px;
}

/* Sticky row background color */
.grid .k-master-row.k-table-alt-row .k-grid-content-sticky {
    background-color: #f7f7f7;
}

/* Sticky row background color */
.grid .k-master-row:not(.k-table-alt-row) .k-grid-content-sticky {
    background-color: #f7f7f7;
}

/* Row background color */
.grid .k-grid-content tr:not(.k-alt) {
    background-color: #f7f7f7;
}

/* Row background color */
.grid .k-grid .k-table-row.k-table-alt-row {
    background-color: #f7f7f7;
}

.grid .k-master-row:hover .k-grid-content-sticky {
    background-color: #ebebeb;
}

/* Cell */
.grid .k-grid td, .grid .k-grid .k-table-td {
    border-bottom: 1px solid;
    border-bottom-color: #dbdbdb;
    border-left: 1px solid transparent;
    font-size: 12px;
    text-align: center;
    color: #1b1b1b;
    /* background-color: white;*/
}

.grid tbody tr {
    line-height: 10px;
}

.grid tbody td {
    padding: 0 !important;
    height: 48px;
}

.grid .k-pager {
    background-color: white;
    border-color: #dbdbdb;
    padding-block: 9px;
    justify-content: end;
}

.grid .k-pager .k-pager-sizes {
    order: 10;
}

.grid .k-pager .k-pager-sizes .k-picker-solid {
    color: #1b1b1b;
    border-color: #dbdbdb;
    background-color: white;
    font-size: 12px;
    background-image: none;
    border-radius: 3px;
}

.grid .k-pager-sizes .k-picker-solid:focus, .grid .k-pager-sizes .k-picker-solid.k-focus {
    box-shadow: none;
    border-color: var(--main-blue-color);
}

.grid .k-pager-sizes > span:last-child {
    display: none;
}

.grid .k-pager-info {
    color: #1b1b1b;
    font-size: 12px;
    flex: none;
}

.grid .k-pager-md .k-pager-numbers-wrap .k-pager-nav {
    width: 32px;
    min-width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: transparent !important;
    border: 1px solid #dbdbdb !important;
    border-radius: 3px;
    color: #1b1b1b;
    transition: all 0.4s;
}

.grid .k-pager-numbers-wrap {
    gap: 5px;
}

.grid .k-pager-md .k-pager-numbers-wrap .k-pager-nav:hover {
    border-color: #e96024 !important;
    background-color: white !important;
    background-image: none;
}

.grid .k-pager-md .k-button:hover::before,
.grid .k-pager-md .k-button.k-hover::before,
.grid .k-pager-md .k-button-flat:focus::after,
.grid .k-pager-md .k-button-flat.k-focus::after {
    opacity: 0;
}

/* Pager buttons color */
.grid .k-pager .k-button-flat-primary {
    color: #1b1b1b;
    font-size: 13px;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    transition: all 0.4s;
}

/* Selected page color */
.grid .k-pager-numbers .k-selected {
    color: #1b1b1b;
    background-color: #e3e3e3 !important;
}

.grid .k-pager-numbers {
    gap: 2px;
}

.grid .k-pager-numbers .k-button:hover, .grid .k-pager-numbers .k-button:focus {
    background-color: #e3e3e3 !important;
}

/* Selected page background */
.grid .k-button.k-selected::before {
    opacity: 0;
}

/* Context menu border radius */
.k-menu-popup {
    border-radius: 2px;
}

.grid-context-menu .k-item {
    border-bottom: 1px solid var(--main-bright-gray-color);
    font-size: 11px;
    font-weight: bold;
}

/* Remove box shadow when target */
.k-menu.k-context-menu .k-item:focus > .k-link {
    box-shadow: none !important;
}

/* Fix mobile touch events */
.mobile .k-grid .k-table.k-grid-table {
    touch-action: auto !important;
}

/* Empty records label */

.grid .k-grid-norecords td {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    font-weight: bold;
    width: 250px !important;
}

.grid .k-grid .k-table-row.k-selected > td {
    background-color: #e3e3e3 !important;
}

.grid .default-overflow-cell {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 40px;
    line-height: 15px;
}

/* default left side content cell */
.grid .default-left-cell {
    width: 100%;
    display: block;
    text-align: left !important;
    padding: 0 10px;
}

/* default flex side content cell */
.grid .default-flex-cell {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

/* default center side content cell */
.grid .default-center-cell {
    width: 100%;
    display: block;
    text-align: center !important;;
}

/* Grid link button in cell */
.grid .cell-button {
    background-color: inherit;
    border: none;
    font-size: 12px;
    cursor: pointer;

}

.grid .cell-button:hover {
    text-decoration: underline;
    color: var(--main-orange-color);
}

.custom-field-edit {
    background-color: rgba(0, 29, 100, .2);
}

.grid .k-grid .k-hierarchy-cell + .k-grid-content-sticky {
    border-inline-start-width: 2px;
}

.grid-entity-label {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
}

.k-grid-content {
    background-color: #f7f7f7 !important;
}
