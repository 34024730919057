
.collapse {
    margin: 5px 10px 10px 10px;
}

.collapseTitle {
    font-size: 14px;
    color: #292929;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
}

.collapseTitleMgb {
    margin-bottom: 2px !important;
}

.collapseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapseIcon svg {
    color: #4A4A4A;
    text-align: center;
    font-size: 5px;
    cursor: pointer;
    transition: all 500ms;

}

.collapseHeader {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
}

.collapseHeaderLine::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #c4c4c4;
}

.collapseContent {
    margin: 0 1px;
    padding: 5px 0;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold !important;
}
