.avatar {
    border-radius: 50%;
    background-color: #7D89BA;
    color: #111C35;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.avatar[data-size='big'] {
    width: 45px;
    height: 45px;
}

.avatar[data-size='small'] {
    width: 30px;
    height: 30px;
}

.avatar[data-size='medium'] {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    font-size: 13px;
}

.online {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-color: rgb(127, 185, 139);
}