.input {
    width: 100%;
    height: 30px;
    padding: 3px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #D8D8D8;
    color: #4E4E4E;
    border-radius: 2px;
    font-family: inherit;
}

.input:focus-visible {
    outline: none;
    border-color: var(--main-blue-color);
}

.input::placeholder {
    color: #959595;
    font-size: 10px;
}

.input:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .8);
}
