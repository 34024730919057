.pen {
    color: rgb(27, 27, 27);
    opacity: 0.7;
    font-size: 15px;
    cursor: pointer;
    margin-right: 5px;
}

.trash {
    color: rgb(27, 27, 27);
    opacity: 0.7;
    font-size: 15px;
    cursor: pointer;
    margin-right: 5px;
}

.pen:hover, .trash:hover {
    opacity: 1;
    color: var(--main-orange-color);
}

.remark {
    margin-bottom: 15px;
}

.remark label {
    font-size: 14px;
    font-weight: bold;
    color: #1b1b1b;
    display: flex;
    align-items: center;
}

.remark > div {
    margin-top: 10px;
    font-size: 14px;
    color: #1b1b1b;
}

.remark[data-display='flex'] {
    display: flex;
    align-items: center;
    gap: 10px;
}

.remark[data-display='flex'] > div {
    margin-top: 0;
}
