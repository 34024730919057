.title {
    text-align: center;
    font-weight: bold;
    color: #4a4a4a;
    font-size: 11px;
}

.radioBtn {
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
}

.radioContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}