.container {
    padding: 0 5px;
}

.bet {
    min-height: 80px;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 2px;
    padding: 5px;
    position: relative;
}

.selectedBet {
    color: #1b1b1b;
}

.actualBet {
    color: #4a4a4a;
}

.rejectedBet {
    color: #BB4945;
}

.history {
    color: var(--main-blue-color);
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 13px;
}

.history:hover {
    color: var(--main-orange-color);
}

.historyDisabled {
    cursor: not-allowed;
}

.historyBet {
    margin: 0 5px;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
}

.historyBetReasonRow {
    display: flex;
    gap: 5px;
    font-size: 13px;
    align-items: center;
    color: #BB4945;
    font-weight: bold;
}

.red {
    color: #BB4945;
}

.gray {
    color: #969696;
}

.betText {
    font-weight: bold;
    font-size: 20px;
}

.orange {
    color: var(--main-orange-color);
}

.predicted {
    font-size: 12px;
}

.serviceProvider {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.betValueRow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.note {
    font-style: italic;
    color: #969696;
}
