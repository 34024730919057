.warning {
    font-size: 18px;
    text-align: center;
    color: #4a4a4a;
}

.checkboxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    font-size: 14px;

    color: #4E4E4E;
}
