.ul {

    border-radius: 3px;
    list-style: none;
    display: inline-block;
    width: auto;
    height: 21px;
    background-color: white;
    white-space: nowrap;
}

.ul li {
    display: inline-block;
    position: relative;
}

.ul li:not(.languageLi) {
    border-left: 1px solid #878787;
    line-height: 20px;
    cursor: pointer;
}

.label {
    font-size: 13px;
    color: #262626;
    margin: 0 7px;
}

.selectedLine {
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: var(--main-orange-color);
    bottom: 0;
}

.languageLi svg {
    color: #878787;
    font-size: 26px;
    position: absolute;
    top: -18px;
    left: -1px;
}

.languageLi div:first-child {
    width: 30px;
}

.languageLi div:not(:first-child) {
    position: absolute;
    height: 20px;
    width: 2px;
    top: -15px;
    background-color: #878787;
}
