.container {
    margin: 5px 20px 10px 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 10px;
}

.tag button {
    font-size: 10px;
    text-transform: uppercase;

}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header > div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
}


.history {
    cursor: pointer;
    color: var(--main-blue-color);
    font-size: 12px;

    display: flex;
    gap: 5px;
    align-items: center;
    border: none;
    background: transparent;
}

.history svg {
    font-size: 14px;
}

.link {
    color: #1B3274;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: not-allowed;
}

.linkActive {
    cursor: pointer;
}

.text {
    color: #1b1b1b;

    font-size: 14px;
}
