.separator {
    width: 100%;
    height: 1px;
    background-color: var(--main-bright-gray-color);
    margin: 10px 0;
}

.button {
    background-color: #7FB98B;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
}

.button svg {
    font-size: 12px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: end;
}

.file {
    display: grid;
    grid-template-columns: 100%;
}

.file > div:first-child {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #555;
    font-size: 12px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}

.file > div:last-child {
    padding: 0 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    border: 1px solid #D8D8D8;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 12px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
