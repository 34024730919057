.btnContainer {
    display: flex;
    align-items: center;
    justify-content: end;
}

.button {
    background-color: var(--main-orange-color);
    color: white;
    border: none;
    border-radius: 2px;
    padding: 3px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
}

.button svg {
    font-size: 12px;
}

.vatNoteContainer {
    display: grid;
    grid-template-columns: 50% 50%;
}

.vatContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.vatContainer > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: var(--main-bright-gray-color);
    margin: 10px 0;
}