.tooltip {
    color: #cccccc;
    font-size: 12px;
    margin: 0 0 0 4px;
}

.tooltipwarn {
    color: var(--red-color);
    font-size: 12px;
    margin: 0 0 0 4px;
}

.tooltip:hover {
    color: black;
}

.icon {
    margin-right: 5px;
    font-size: 13px;
}

.icon[data-sp-type='[P]'] {
    color: var(--main-orange-color);
}

.icon[data-sp-type='[D]'] {
    color: #7FB98B;
}

.spLabel {
    display: flex;
    margin-bottom: 2px;
}

.spList {
    background-color: rgb(234, 239, 243);
    border-radius: 2px;
    overflow: hidden;
}

.address {
    color: #4a4a4a;
    font-weight: bold;
    font-size: 12px;
}

.datePickerContainer {
    display: flex;
}
