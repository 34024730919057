.wrap{
    overflow: auto;
    height: 100%;
}

.inner {
    margin: 10px auto;
    max-width: 1400px;
    font-size: 20px;
    padding: 0 16px;

    @media (max-width: 767.98px) {
        font-size: 18px;
    }

    @media (max-width: 575.98px) {
        font-size: 16px;
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 767.98px) {
        font-size: 25px;
    }

    @media (max-width: 575.98px) {
        font-size: 20px;
    }
}

.item {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}

.subtitle {
    margin-right: 25px;
    margin-bottom: 5px;
}

.bold {
    font-weight: 600;
}

.choose {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 25px;
    position: relative;

    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.radio {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.field {
    max-width: 300px;
}

.languageSwitcherContainer {
    padding: 5px;
    display: flex;
    justify-content: end;
}

.tooltip {
    display: inline-block;
    border: 2px solid red;
    color: red;
    padding: 5px;
}

.error {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
}

.btn {
    margin-top: 20px;
    margin-bottom: 100px;

    @media (max-width: 575.98px) {
        display: flex;
        justify-content: center;
    }
}

.radiobuttons {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.innerHidden {
    display: none;
}

.successSubmitted {
    margin-top: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

   span {
    border: 3px solid var(--main-orange-color);
    padding: 30px 30px 30px 60px;
    position: relative;
    font-size: 18px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 20px;
        width: 19px;
        height: 19px;
        background: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.29883 10.7402L6.68129 14.4609L15.7012 4.53906' stroke='%2331A66D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
    }
   }
}
