.text {
    vertical-align: middle;
    cursor: pointer;
}

.addText {
    color: #e96024;
    cursor: pointer;
    vertical-align: middle;
}

.icon {
    font-size: 14px;
    color: #e96024;
    margin-right: 5px;
    vertical-align: middle;
    cursor: pointer;
}

.iconWithValue {
    color: #4e4e4e;
}
