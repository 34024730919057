.header {
    font-size: 15px;
    padding: 5px 0;
    font-weight: bold;
}

.container {
    display: flex;
    flex-direction: column;
}

.container > div:last-child {
    flex: 1;
}

.content {
    display: grid;
    grid-template-columns: calc(100% - 300px) 300px;
}

@media screen and (max-width: 946px) {
    .content {
        display: block;
    }
}