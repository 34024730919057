.filter {
    font-size: 15px;
    color: #cccccc;
}

.active {
    color: var(--main-blue-color);
}

.count {
    font-size: 11px;
    color: var(--main-orange-color);
    font-weight: bold;
}