.alert {
    display: flex;
    align-items: center;
    gap: 10px;
}

.alertBtn {
    background-color: inherit;
    border: 1px solid black;
    font-weight: bold;
    cursor: pointer;
    color: black;
    font-size: 11px;
    border-radius: 2px;
    padding: 1px 5px;
}