.container {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2px;
}

.container span {
    color: var(--main-orange-color);
    font-size: 10px;
}

.container:hover span {
    color: var(--main-blue-color);
    text-decoration: underline;
}

.container img {
    width: 20px;
}
