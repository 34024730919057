.button {
    background-color: inherit;
    border: none;
    font-size: 10px;
    cursor: pointer;

}

.button:hover {
    text-decoration: underline;
    color: var(--main-orange-color);
}

.cell {
    width: 100%;
    display: block;
    text-align: left !important;
    padding: 0 10px;
}
